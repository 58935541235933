import httpBuildQuery from 'http-build-query'
// for test
// eslint-disable-next-line
import { appMessenger } from '../../5_entities/Configuration/lib'
import {
  cache,
  getMilliseconds,
  LocalStorageNames,
  SessionStorageNames,
  useLocalStorage,
  useSessionStorage
} from '../lib'
import { cancelRequestService } from './CancelRequest.service'
import type { ErrorsHandbook } from './error'
import { ErrorInterpreter } from './error'
import type { RequestOptions } from './model'
import { http } from './model'

// отмена повторных запросов ✅
// отмена запроса при смене роута ✅
// отмена по таймауту ✅
// кэширование ✅
// обработка ошибок ✅
// моки ✅
// авторизация(?)

export class HttpService {
  private _cancelRequestService = cancelRequestService
  private _cacheService = cache
  private _errorInterpreter: ErrorInterpreter
  private readonly _errorsHandbook: ErrorsHandbook

  constructor(errorsHandbook: ErrorsHandbook) {
    this._errorsHandbook = errorsHandbook
    this._errorInterpreter = new ErrorInterpreter(this._errorsHandbook)
  }

  public async get<T>(url: string, params?: RequestOptions<T>): Promise<T> {
    const requestParams: RequestOptions<T> = {
      ...params,
      method: 'GET'
    }

    return this._createRequest<T>(url, requestParams)
  }

  public async post<T>(url: string, params?: RequestOptions<T>): Promise<T> {
    const requestParams: RequestOptions<T> = {
      ...params,
      method: 'POST'
    }

    return this._createRequest<T>(url, requestParams)
  }

  public async put<T>(url: string, params?: RequestOptions<T>): Promise<T> {
    const requestParams: RequestOptions<T> = {
      ...params,
      method: 'PUT'
    }

    return this._createRequest<T>(url, requestParams)
  }

  public async delete<T>(url: string, params?: RequestOptions<T>): Promise<T> {
    const requestParams: RequestOptions<T> = {
      ...params,
      method: 'DELETE'
    }

    return this._createRequest<T>(url, requestParams)
  }

  public async postFormData<T>(url: string, params: RequestOptions<T>): Promise<T> {
    const formData = new FormData()
    const formBody = params.body as Record<string, any>

    Object.entries(formBody).forEach(([key, value]) => {
      formData.set(key, value)
    })

    const requestParams: RequestOptions<T> = {
      ...params,
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return this._createRequest(url, requestParams)
  }

  private _getRequestId<T>(url: string, generalUrl?: RequestOptions<T>['generalUrl']) {
    return generalUrl || url
  }

  private _createRequest<T>(url: string, params: RequestOptions<T>): Promise<T> {
    const requestId = this._getRequestId<T>(url, params.generalUrl)
    this._handleAbortRequest<T>(requestId, params)
    const { url: requestUrl, params: requestParams } = this._createHttpBuildQuery(url, params)

    params.clearDataOnRequest && this._clearCache()

    if (params?.mock)
      return new Promise<T>((res) => {
        console.log(`request ${url} with `, params.params, params.body, params.query)
        console.log(`response ${url} with mock: `, params.mock)
        setTimeout(() => res(params.mock as T), 1000)
      })

    const cachedItem = this._getRequestDataFromCache<T>(requestId)

    if (cachedItem) return Promise.resolve(cachedItem)

    return http<T>(requestUrl, requestParams)
      .then((data) => {
        this._cancelRequestService.deleteAbortController(requestId)

        params.cacheConfig &&
          this._setRequestDataToCache<T>(requestId, data, params.cacheConfig.lifeTime)

        return data
      })
      .catch(async (error: any) => {
        this._sendAppMessageOnError<T>(error, url, params)

        if (!url.includes('user') && !url.includes('auth') && error?.statusCode === 401) {
          return await this._handleUnauthorizedError<T>(() => this._createRequest<T>(url, params))
        }

        throw this._errorInterpreter.interpret(error)
      })
  }

  private _createHttpBuildQuery = <T>(url: string, params: RequestOptions<T>) => {
    if (!params.queryAsString) return { url, params }

    const query = httpBuildQuery(params.query, 'flags_')

    return {
      url: `${url}?${query}`,
      params: { ...params, query: undefined }
    }
  }

  /**
   * пытаемся рефрешнуть токен и переотправить запрос
   * @param callback
   * @private
   */
  private async _handleUnauthorizedError<T>(callback: () => Promise<T>) {
    await this._refreshUserToken()

    return await callback()
  }

  /**
   * получаем сохраненного юзера и перезапрашиваем токен
   * @private
   */
  private async _refreshUserToken() {
    const userId = useSessionStorage().get(SessionStorageNames.userId)
    const partnerKey = useLocalStorage().get(LocalStorageNames.partnerKey)

    const getUserFields = () =>
      userId && partnerKey
        ? {
            userId: userId,
            partnerKey: partnerKey
          }
        : null

    if (!getUserFields()) throw new Error('User fields not found')

    await http('app/auth', {
      method: 'POST',
      retry: 3,
      timeout: getMilliseconds.inSeconds(1),
      body: getUserFields()
    }).then((data) => useLocalStorage().set(LocalStorageNames.authToken, data.authToken))
  }

  private _sendAppMessageOnError<T>(error: any, url: string, params: RequestOptions<T>) {
    if (!params.appMessageErrors) return

    if (params.appMessageErrors.includes(error.statusCode))
      switch (error.statusCode) {
        case 404:
          appMessenger.error.http.notFound(404, url)
      }
  }

  // Отмена запросов
  private _handleAbortRequest<T>(requestId: string, params: RequestOptions<T>) {
    this._tryToAbortRepeatedRequest(requestId)
    this._addAbortControllerToRequest<T>(requestId, params)
  }

  private _tryToAbortRepeatedRequest(abortControllerId: string) {
    this._cancelRequestService.callControllerAbort(abortControllerId)
  }

  private _addAbortControllerToRequest<T>(abortControllerId: string, params: RequestOptions<T>) {
    if (params.irrevocable) return

    this._cancelRequestService.addAbortController(abortControllerId, params.needCancelOnRouteChange)
    params.signal = this._cancelRequestService.getAbortControllerSignal(abortControllerId)
  }

  // cache
  private _getRequestDataFromCache<T>(requestId: string): T | undefined {
    return this._cacheService.get<T>(requestId)
  }

  private _setRequestDataToCache<T>(requestId: string, data: T, lifeTime: number) {
    this._cacheService.set(requestId, { data, lifeTime })
  }

  private _clearCache() {
    this._cacheService.clear()
  }
}

export class BaseService {
  protected _http: HttpService
  protected readonly _handbook: ErrorsHandbook

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    this._handbook = errorsHandbook
    this._http = new http(this._handbook)
  }
}
