<template>
  <UiButton v-if="scrollY > 300" icon severity="tertiary" class="size-12" @click="scrollToTop">
    <UiIcon name="chevron-down" class="size-4 rotate-180" />
  </UiButton>
</template>

<script setup lang="ts">
import throttle from 'lodash/throttle.js'
import { onMounted, onUnmounted, ref } from 'vue'
import UiButton from './UiButton.vue'
import UiIcon from './UiIcon.vue'

const scrollY = ref(0)

const scrollHandler = throttle(function () {
  scrollY.value = window.scrollY
}, 100)
const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

onMounted(() => window.addEventListener('scroll', scrollHandler))
onUnmounted(() => window.removeEventListener('scroll', scrollHandler))
</script>
