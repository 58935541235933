<template>
  <li>
    <header class="mb-3 flex items-center justify-between px-4">
      <UiTitle tag="h3" severity="h6" class="text-text-fivefold">
        {{ value.name }}
      </UiTitle>
      <RouterLink
        v-if="remainingItemsLength > 0"
        :to="{ name: 'SearchCategoryView', params: { id: value.id } }"
      >
        <UiButton text>
          <ThemeIcon name="arrow-right" class="ml-0.5 size-5 text-text-primary" />
        </UiButton>
      </RouterLink>
    </header>
    <SearchResultList :value="viewedResults" />
  </li>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { ThemeIcon } from '@/5_entities/Configuration'
import { type SearchResultsItem, SearchResultList } from '@/5_entities/Search'
import { UiButton, UiTitle } from '@/6_shared/ui'

type PropType = {
  value: SearchResultsItem
}

const props = defineProps<PropType>()

const MAX_ITEMS = 5
const viewedResults = computed(() => props.value.repertoire.filter((_, count) => count < MAX_ITEMS))
const remainingItemsLength = computed(() => props.value.repertoire.length - MAX_ITEMS)
</script>
