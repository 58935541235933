<template>
  <div>
    <UiDate :value="seance.beginsAt" :template="templateFirst" /> -
    <UiDate :value="seance.endsAt!" :template="templateLast" />
  </div>
</template>

<script lang="ts" setup>
import { isSameMonth, isSameYear } from 'date-fns'
import { computed } from 'vue'
import type { Seance } from '@/5_entities/Seance'
import { UiDate } from '@/6_shared/ui'

/*
Условие: Один или несколько сеансов у мероприятия
И Есть хотя бы один переходящий сеанса на следующий день.

Сценарий 2.1 Один сеанс у мероприятия. Переходящий на следующий день/месяц/год

сеанс переходит на следующий день в рамках одного месяца
пример: 1 - 2 сентября ✅

сеанс переходит на следующий месяц
пример: 30 сентября - 1 октября ✅

сеанс переходит на следующий год
пример: 31 декабря - 2 января 2025 ✅

Сценарий 2.2 Несколько сеансов у мероприятия и есть хотя бы один переходящий ✅
игнорируем специальную логику работы с переходящим сеансом и работаем со всеми сценариями в рамках мероприятияй, по логике "Основной"
 */

type PropType = {
  seance: Seance
}

const props = defineProps<PropType>()

const isDayWrap = computed(() => {
  const { beginsAt, endsAt } = props.seance

  return isSameMonth(beginsAt, endsAt!)
})

const isMonthWrap = computed(() => {
  const { beginsAt, endsAt } = props.seance

  return isSameYear(beginsAt, endsAt!)
})

const templateFirst = computed(() => {
  const isAnotherYear = !isSameYear(props.seance.beginsAt, new Date())

  if (isDayWrap.value) return 'd'
  if (isMonthWrap.value) return `d MMMM${isAnotherYear ? ' yyyy' : ''}`

  return `d MMMM${isAnotherYear ? ' yyyy' : ''}`
})

const templateLast = computed(() => {
  const isAnotherYear = !isSameYear(props.seance.endsAt!, new Date())

  return `d MMMM${isAnotherYear ? ' yyyy' : ''}`
})
</script>
