<template>
  <section class="px-4 py-2">
    <ThemeConstructorPropertyHeader v-model:open="open" :title="title" />
    <div v-show="open">
      <template
        v-for="([themeKey, themeValue], _valueKey, index) in Object.entries(value)"
        :key="index"
      >
        <ChangeThemeConstructorColors
          v-if="isPlainObject(themeValue)"
          :value="themeValue"
          :title="themeKey"
          @change-color="changeThemeColorFromChild($event, themeKey)"
          @mouseenter="onChildMouseenter($event, themeKey)"
          @mouseleave="emit('mouseleave')"
        />
        <EditThemeColor
          v-else
          :title="themeKey"
          :value="themeValue"
          @change-color="changeThemeColor"
          @mouseenter="onMouseEnter(themeKey)"
          @mouseleave="emit('mouseleave')"
        />
      </template>
    </div>
  </section>
</template>

<script lang="ts" setup>
import isPlainObject from 'lodash/isPlainObject.js'
import { ref } from 'vue'
import {
  type ThemeConfigurationChangeColorEmit,
  ThemeConstructorPropertyHeader
} from '@/5_entities/Configuration'
import EditThemeColor from './EditThemeColor.vue'

type PropType = {
  title: string
  value: Record<string, string> | string
}

type EmitType = {
  (e: 'changeColor', obj: ThemeConfigurationChangeColorEmit): void
  (e: 'mouseenter', title: string): void
  (e: 'mouseleave'): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()

const open = ref(false)

const changeThemeColor = ({ color, themeKey }: ThemeConfigurationChangeColorEmit) => {
  emit('changeColor', { color, themeKey })
}

const changeThemeColorFromChild = (
  { color, themeKey }: ThemeConfigurationChangeColorEmit,
  newKey: string
) => {
  emit('changeColor', { color, themeKey: `${newKey}-${themeKey}` })
}

const onMouseEnter = (title: string) => {
  emit('mouseenter', title)
}
const onChildMouseenter = (childTitle: string, title: string) => {
  emit('mouseenter', `${title}-${childTitle}`)
}
</script>
