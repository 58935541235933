<template>
  <div
    class="grid w-full"
    :style="{ gap, 'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))` }"
  >
    <div
      v-for="(column, index) in visibleColumns"
      :key="`column-${index}`"
      :style="{ gap, minWidth: `${100 / columns}%` }"
      class="flex w-full flex-col"
    >
      <div v-for="(item, itemIndex) in column" :key="`item-${itemIndex}`">
        <slot :item="item" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T">
import { computed, type VNode } from 'vue'

type PropType = {
  items: T[]
  columns: number
  gap?: string
}
type SlotType = {
  default?: (props: { item: T }) => VNode
}

const props = withDefaults(defineProps<PropType>(), {
  gap: '24px'
})
defineSlots<SlotType>()

const visibleColumns = computed((): any[][] => {
  const columns: any[][] = []

  for (let column = 0; column < props.columns; column++) {
    columns.push([])
  }

  for (let index = props.columns - 1; index >= 0; index--) {
    props.items.forEach((item: T, itemIndex: number) => {
      if (index === itemIndex || (itemIndex - index) % props.columns === 0) return columns[index].push(item)
    })
  }

  return columns
})
</script>
