<template>
  <section>
    <template v-if="value && !loading">
      <Component
        :is="componentsMap[type]"
        v-show="value.items.length"
        :value="value"
        :size="size"
      />
      <UiContainer v-show="!value.items.length" class="text-sm text-text-primary">
        Подходящих событий не найдено. Попробуйте сбросить фильтр<br />или выбрать другие параметры
        поиска
      </UiContainer>
    </template>
    <template v-else>
      <CompilationWidgetSkeleton :type="type" />
    </template>
  </section>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import { type Compilation } from '@/5_entities/Compilation'
import { UiContainer } from '@/6_shared/ui'
import CompilationWidgetSkeleton from './CompilationWidgetSkeleton.vue'

type PropType = {
  value?: Compilation
  loading?: boolean
  maxItems?: number
  type?: 'list' | 'masonry'
  size?: 'small' | 'big'
}

withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false,
  maxItems: undefined,
  type: 'list',
  size: 'big'
})

const CompilationList = defineAsyncComponent(async () => {
  const { CompilationList } = await import('@/5_entities/Compilation')

  return CompilationList
})
const CompilationMasonry = defineAsyncComponent(async () => {
  const { CompilationMasonry } = await import('@/5_entities/Compilation')

  return CompilationMasonry
})

const componentsMap: Record<
  NonNullable<PropType['type']>,
  typeof CompilationList | typeof CompilationMasonry
> = {
  list: CompilationList,
  masonry: CompilationMasonry
}
</script>
