<template>
  <main class="py-5">
    <CategoryMainHeader class="mb-6" @select-date="selectFilter" />
    <div v-if="categories" class="flex flex-col gap-6">
      <CategoryResults
        v-for="category in categories"
        :key="category.id"
        :value="category"
        :loading="!categoriesInitialized"
        :max-items="5"
      />
    </div>
    <CategoryResults v-else :loading="!categoriesInitialized" />
    <UiEmptySearch
      v-if="categories && categories.length === 0"
      class="absolute h-full justify-center inset-center"
    />
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { CategoryResults } from '@/3_widgets/Category'
import { CategoryMainHeader } from '@/3_widgets/Category/ui'
import { useCategory } from '@/5_entities/Category'
import { formatDateForFilter } from '@/6_shared/lib'
import { UiEmptySearch } from '@/6_shared/ui'

const router = useRouter()

const selectFilter = (filter: Undefinable<Date | Date[]>) => {
  if (!filter) return

  const query = Array.isArray(filter)
    ? { fromDateTime: formatDateForFilter(filter[0]), tillDateTime: formatDateForFilter(filter[1]) }
    : { fromDateTime: formatDateForFilter(filter) }

  router.push({ name: 'CategoryDateView', query })
}

const { categories, categoriesInitialized } = storeToRefs(useCategory())
const { getAllCategories } = useCategory()

onBeforeMount(() => getAllCategories())
</script>
