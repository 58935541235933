<template>
  <main>
    <UiCheckbox v-model="datePickerRange">datePickerRange</UiCheckbox>
    <UiCheckbox v-model="datePickerConfirm">datePickerConfirm</UiCheckbox>
    <UiButton @click="showDatePicker = !showDatePicker"> date picker </UiButton>
    <UiDialog
      v-model="showDatePicker"
      position="bottom"
      close-position="left"
      close-classes="-top-1 left-2"
      class="h-[97vh] rounded-t-3xl bg-primary pt-4"
      swipe-close
    >
      <template #header>
        <div class="w-full bg-white text-center">
          <UiTitle severity="h2" class="mx-auto">Расписание</UiTitle>
        </div>
      </template>
      <UiDatePicker
        v-model="selectedDate"
        :range="datePickerRange"
        :need-confirm="datePickerConfirm"
        @close="showDatePicker = false"
      />
    </UiDialog>
    <p>selected date {{ selectedDate }}</p>

    <UiContainer class="z-20 -mb-3 bg-tertiary" rounded="bottom">
      <UiButton @click="closeApp">Закрыть приложение</UiButton>
      <UiButton loading> Все мероприятия </UiButton>
      <UiButton> Все мероприятия </UiButton>
      <UiButton disabled> disabled primary </UiButton>
      <UiButton disabled severity="secondary"> disabled secondary </UiButton>
      <UiButton disabled severity="tertiary"> disabled tertiary </UiButton>
      <UiButton icon class="size-12" severity="secondary">
        <ThemeIcon name="arrow-left" class="size-4" />
      </UiButton>
      <UiButton size="small"> Все мероприятия </UiButton>
      <UiButton text size="small"> Text btn </UiButton>
      <UiButton severity="secondary"> secondary </UiButton>
      <UiButton severity="tertiary"> tertiary </UiButton>
      <UiBadge>10% кешбэка</UiBadge>
      <UiBadge severity="secondary">10% кешбэка</UiBadge>
      <UiBadge severity="tertiary">10% кешбэка</UiBadge>
    </UiContainer>
    <UiContainer rounded="none" class="z-10 bg-side-primary py-7">
      <UiButton loading> Все мероприятия </UiButton>
      <UiButton icon class="size-12" severity="secondary">
        <ThemeIcon name="arrow-left" class="size-4" />
      </UiButton>
      <UiButton size="small"> Все мероприятия </UiButton>
      <UiButton text size="small"> Text btn </UiButton>
      <UiButton severity="secondary"> secondary </UiButton>
      <UiButton severity="tertiary"> tertiary </UiButton>
      <UiBadge>10% кешбэка</UiBadge>
      <UiBadge severity="secondary">10% кешбэка</UiBadge>
      <UiBadge severity="tertiary">10% кешбэка</UiBadge>
    </UiContainer>
    <UiContainer class="z-20 -mb-24 -mt-3 bg-primary pb-24">
      <UiButton loading> Все мероприятия </UiButton>
      <UiButton icon class="size-12" severity="secondary">
        <ThemeIcon name="arrow-left" class="size-4" />
      </UiButton>
      <UiButton size="small"> Все мероприятия </UiButton>
      <UiButton text size="small"> Text btn </UiButton>
      <UiButton severity="secondary"> secondary </UiButton>
      <UiButton severity="tertiary"> tertiary </UiButton>
      <UiBadge>10% кешбэка</UiBadge>
      <UiBadge severity="secondary">10% кешбэка</UiBadge>
      <UiBadge severity="tertiary">10% кешбэка</UiBadge>
      <div class="text-primary">{{ input }}</div>

      <UiInput v-model.trim="input" disabled type="search" class="pl-10" placeholder="primary">
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput v-model.trim="input" type="text" class="pl-10" placeholder="primary">
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput v-model.trim="input" type="text" class="pl-10" placeholder="primary">
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        type="search"
        class="pl-10"
        placeholder="primary"
        :errors="['Ошибка']"
      >
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput
        v-model="input"
        type="number"
        severity="secondary"
        class="pl-10 text-center"
        placeholder="secondary"
      >
        <template #prepend>
          <p
            class="absolute left-4 top-0 flex h-full items-center justify-center text-xs text-text-tertiary"
          >
            До
          </p>
        </template>
      </UiInput>

      <UiCheckbox v-model="checkbox" :errors="['Ошибка']"> this is checkbox </UiCheckbox>
      <UiCheckbox v-model="checkbox"> this is checkbox </UiCheckbox>
      <UiCheckbox v-model="checkbox" disabled> this is checkbox </UiCheckbox>

      <UiSwiperCards>
        <SwiperSlide
          v-for="item in 4"
          :key="item"
          :style="{ background: colors[item - 1] }"
          class="size-40"
        >
          {{ item }}
        </SwiperSlide>
      </UiSwiperCards>

      <UiSwiperPagination>
        <SwiperSlide
          v-for="item in 4"
          :key="item"
          :style="{ background: colors[item - 1] }"
          class="size-40"
        >
          {{ item }}
        </SwiperSlide>
      </UiSwiperPagination>

      {{ slider }}
      <br />
      <UiButton @click="showModalBottom = !showModalBottom"> show modal bottom </UiButton>

      <UiDialog
        v-model="showModalBottom"
        position="bottom"
        close-position="left"
        close-classes="-top-2"
        swipe-close
        class="h-[97vh] rounded-t-3xl bg-white p-4"
      >
        <template #header>
          <div class="grid w-full grid-cols-3">
            <div />
            <p class="mx-auto">this is header</p>
            <p class="ml-auto">cancel</p>
          </div>
        </template>
        <div>this is modal bottom</div>
      </UiDialog>

      <UiButton @click="showModalFade = !showModalFade"> show modal fade </UiButton>
      <UiButton @click="showPopover($event)"> Popover </UiButton>
      <br />
      <UiDialog v-model="showModalFade" position="center" class="h-screen w-screen bg-white p-4">
        <div>this is modal fade</div>
      </UiDialog>

      <UiPopover ref="popover">
        <div>this is popover this is popover</div>
      </UiPopover>

      <UiDate value="08.04.1995" />
    </UiContainer>
  </main>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'
import { ThemeIcon } from '@/5_entities/Configuration'
import {
  UiBadge,
  UiButton,
  UiCheckbox,
  UiContainer,
  UiDate,
  UiDatePicker,
  UiDialog,
  UiInput,
  UiPopover,
  UiSwiperCards,
  UiSwiperPagination,
  UiTitle
} from '@/6_shared/ui'

const input = ref('')
const checkbox = ref(false)
const slider = ref(0)
const colors = ['yellow', '#E5e', '#234', '#576']
const showModalBottom = ref(false)
const showModalFade = ref(false)
const popover = ref<typeof UiPopover | null>(null)

const showPopover = (e: MouseEvent) => {
  popover.value!.show(e)
}

const closeApp = () => window.close()

const showDatePicker = ref(false)
const datePickerRange = ref(true)
const datePickerConfirm = ref(true)
const selectedDate = ref<Date | Date[] | undefined>()
</script>
