<template>
  <SearchResultCard tag="li" class="px-4" :link="itemLink">
    <EventCard v-if="value.type === 'event'" :value="value.object as Event" hide-category />
    <VenueCard v-else-if="value.type === 'venue'" :value="value.object as Venue" />
  </SearchResultCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { EventCard, type Event } from '@/5_entities/Event'
import { type Venue, VenueCard } from '@/5_entities/Venue'
import type { SearchResultsObject } from '../../model'
import SearchResultCard from './SearchResultCard.vue'

type PropType = {
  value: SearchResultsObject
}

const props = defineProps<PropType>()

const itemLink = computed(() => {
  const initial = {
    params: { id: props.value.object.id }
  }

  return props.value.type === 'event'
    ? { name: 'EventView', ...initial }
    : { name: 'VenueView', ...initial }
})
</script>
