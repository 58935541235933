<template>
  <UiButton
    v-if="visible && value?.length"
    class="!flex max-h-[60px] items-center overflow-hidden !rounded-2xl border border-main !bg-primary !p-0 text-base font-semibold text-text-primary"
    @click="onButtonClick"
  >
    <div class="mr-4 h-[60px] w-full max-w-1/5">
      <UiPicture
        :src="image"
        img-classes="!object-cover"
        :alt="eventTitle"
        :options="{ height: 120 }"
        class="h-full !w-full !max-w-max object-cover"
      >
        <template #default-image>
          <UiBackupImage
            :letter="eventTitle[0]"
            :alt="eventTitle"
            class="mr-4 h-[60px] w-[200px] min-w-full max-w-1/4"
          />
        </template>
      </UiPicture>
    </div>

    <p class="py-4.5">
      <template v-if="eventHasOneSeance"> купить билет </template>
      <template v-else-if="eventHasOneDay"> выбрать время </template>
      <template v-else> выбрать дату и время </template>
    </p>

    <UiIcon name="chevron-down" class="ml-auto mr-2 size-3 min-w-3 -rotate-90 text-icon-primary" />
  </UiButton>
</template>

<script lang="ts" setup>
import { isSameDay } from 'date-fns'
import type { Nullable } from 'ts-helpers'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import type { Seance } from '@/5_entities/Seance'
import { getElementByGlobalId, GlobalIds } from '@/6_shared/config'
import { UiBackupImage, UiButton, UiIcon, UiPicture } from '@/6_shared/ui'

type PropType = {
  value: Seance[]
  image: string
  eventTitle: string
  visible: boolean
}
type EmitType = {
  (e: 'update:visible', value: boolean): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const router = useRouter()

const eventHasOneSeance = computed(() => props.value.length === 1)
const eventHasOneDay = computed(() => {
  if (!props.value.length) return false

  const day = props.value[0].beginsAt
  for (const seance of props.value) {
    if (!isSameDay(day, seance.beginsAt)) return false
  }

  return true
})

// логика нажатия на кнопку
const onButtonClick = () => {
  if (eventHasOneSeance.value) {
    router.push({ name: 'SeanceView', params: { id: props.value[0].id } })
  }

  getElementByGlobalId(GlobalIds['eventTimetablePreview'])?.scrollIntoView({
    behavior: 'smooth'
  })
}

//логика скрытия кнопки при скролле над расписанием
// контейнер расписания, на котором надо скрывать
const timetableElement = ref<Nullable<HTMLElement>>(null)

onMounted(() => {
  timetableElement.value = getElementByGlobalId(GlobalIds['eventTimetablePreview'])
})

// IntersectionObserver
let observer: Nullable<IntersectionObserver> = null

const intersectionObserverOptions: IntersectionObserverInit = {
  rootMargin: '0px',
  threshold: 0.1
}

const initObserver = () => {
  if (!timetableElement.value) {
    // из за немгновенной записи ссылки на html,
    // убираем моргание кнопки
    emit('update:visible', true)

    return
  }

  observer = new IntersectionObserver(intersectionObserverCallBack, intersectionObserverOptions)
  observer.observe(timetableElement.value)
}

const intersectionObserverCallBack: IntersectionObserverCallback = function (
  entries: IntersectionObserverEntry[]
) {
  entries.forEach((entry) => {
    const { isIntersecting } = entry

    emit('update:visible', !isIntersecting)
  })
}

const destroyObserver = () => {
  if (!observer || !timetableElement.value) return

  observer.unobserve(timetableElement.value)
  observer = null
}

onMounted(() => initObserver())
onUnmounted(() => destroyObserver())
</script>
