<template>
  <main>
    <section class="mb-4 flex w-fit flex-col">
      <UiTitle>Активная страница:</UiTitle>
      <select v-model="selectedPage" class="rounded border p-2">
        <option v-for="route in pages" :key="route.name" :value="route.name">
          {{ pagesTitle[route.name as string] }}
        </option>
      </select>
    </section>
    <section class="flex">
      <template v-if="theme">
        <ConfigurationThemeConstructor
          v-if="!isMobile"
          :value="theme"
          :is-mobile="isMobile"
          @prop-change="onPropChange"
          @close-modal="showModal = false"
          @theme-upload="setNewTheme"
          @highlight-prop="onHighlightProp"
          @off-highlight="offHighlight"
        />
        <template v-else>
          <UiButton icon class="!fixed bottom-2 right-2 z-20 size-10" @click="showModal = true">
            <UiIcon name="cog-6-tooth" class="size-5" />
          </UiButton>
          <UiDialog v-model="showModal" close-position="right" close-classes="top-0 right-2 z-20">
            <ConfigurationThemeConstructor
              :value="theme"
              :is-mobile="isMobile"
              @prop-change="onPropChange"
              @close-modal="showModal = false"
              @theme-upload="setNewTheme"
            />
          </UiDialog>
        </template>
      </template>
      <div class="relative z-10 flex h-screen w-1/2 justify-center" :class="{ 'w-full': isMobile }">
        <iframe
          ref="iframe"
          :src="activePage"
          width="460px"
          height="100%"
          class="rounded border border-primary"
          :onload="initThemeBuilder"
        />
      </div>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { isMobile } from '@basitcodeenv/vue3-device-detect'
import type { Nullable, Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import { routes } from '@/1_app/providers/router/routes.ts'
import { ConfigurationThemeConstructor } from '@/3_widgets/Configuration'
import {
  partnerKey,
  type Theme,
  type ThemeConfigurationChangePropEmit
} from '@/5_entities/Configuration'
import {
  AppMessengerGetMessageNames,
  AppMessengerPostMessageNames
} from '@/5_entities/Configuration'
import { formatDateForFilter } from '@/6_shared/lib'
import { UiButton, UiDialog, UiIcon, UiTitle } from '@/6_shared/ui'

const partnerKeyValue = partnerKey.get()
const iframe = ref<Nullable<HTMLIFrameElement>>(null)
const readyToWork = ref(false)
const theme = ref<Undefinable<Theme>>(undefined)
const showModal = ref(false)

const getStand = (): Stand => {
  const url = window.config.VITE_APP_BASE_URL

  if (url.includes('dev1')) return 'dev1'
  if (url.includes('dev2')) return 'dev2'

  return 'store.intickets.dev'
}

const dynamicPagesNames = ['EventView', 'VenueView', 'CategoryItemView', 'CategoryDateView']
const pagesWithIds: Record<
  Stand,
  { name: Partial<keyof typeof pagesTitle>; params?: any; query?: any }[]
> = {
  dev1: [
    {
      name: 'EventView',
      params: { id: 274 }
    },
    {
      name: 'VenueView',
      params: { id: 5153 }
    },
    {
      name: 'CategoryItemView',
      params: { id: 0 }
    },
    {
      name: 'CategoryDateView',
      query: { dateFrom: formatDateForFilter(new Date()) }
    }
  ],
  dev2: [
    {
      name: 'CategoryItemView',
      params: { id: 0 }
    },
    {
      name: 'CategoryDateView',
      query: { dateFrom: formatDateForFilter(new Date()) }
    }
  ],
  'store.intickets.dev': [
    {
      name: 'EventView',
      params: { id: 528 }
    },
    {
      name: 'VenueView',
      params: { id: 12643 }
    },
    {
      name: 'CategoryItemView',
      params: { id: 0 }
    },
    {
      name: 'CategoryDateView',
      query: { dateFrom: formatDateForFilter(new Date()) }
    }
  ]
}
const selectedPage = ref('HomeView')
const pages = routes.filter((item) => {
  const isOpened = !item?.meta?.disableOnThemeConstructor
  const dynamicPages = pagesWithIds[getStand()]

  return (
    isOpened &&
    (!dynamicPagesNames.includes(item.name as string) ||
      !!dynamicPages.find((page) => page.name === item.name))
  )
})
const pagesTitle: Record<string, string> = {
  HomeView: 'Лучшее',
  NotFoundView: '404',
  InternalServerErrorView: '500',
  UiKitView: 'UiKit',
  SearchView: 'Поиск',
  EventView: 'Событие',
  VenueView: 'Площадка',
  SettingsView: 'Настройки',
  CategoryMainView: 'Категории',
  CategoryItemView: 'Категория',
  CategoryDateView: 'Категория по дате'
}
type Stand = 'dev1' | 'dev2' | 'store.intickets.dev'

const activePage = computed(() => {
  const activeRoute = routes.find((item) => item.name === selectedPage.value)

  if (!activeRoute) return
  const pageWithId = pagesWithIds[getStand()].find((item) => item.name === selectedPage.value)

  if (pageWithId) {
    let { path } = activeRoute
    let { query } = pageWithId

    pageWithId.params &&
      Object.entries(pageWithId.params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, String(value))
      })
    query && (query = new URLSearchParams(query).toString())

    return `${path}?partnerKey=${partnerKeyValue}&${query}`
  }

  return `${activeRoute.path}?partnerKey=${partnerKeyValue}`
})

/**
 * инициализирует форму редактирования темы после загрузки iframe
 */
let channel = new MessageChannel()
const initThemeBuilder = () => {
  if (!iframe.value) return
  channel = new MessageChannel()

  iframe.value!.contentWindow!.postMessage('capturePort', '*', [channel.port2])

  channel.port1.onmessage = (e) => {
    const json = JSON.parse(e.data)

    json.name.includes(AppMessengerPostMessageNames.themeInit) &&
      (theme.value ? setNewTheme(theme.value) : setTheme(json.params.theme))
    json.name.includes(AppMessengerPostMessageNames.open) && setReadiness()
  }
}

const setReadiness = () => {
  !readyToWork.value && (readyToWork.value = true)
}

const setTheme = (data: Theme) => {
  theme.value = data
}

/**
 * обработка смены параметра темы
 * @param value
 * @param variable
 */
const onPropChange = ({ value, variable }: ThemeConfigurationChangePropEmit) => {
  const variableSplitted = variable.split('-')

  const setThemeProp = (theme: any) => {
    const first = variableSplitted.shift()

    if (!first) return

    typeof theme[first] === 'string' ? (theme[first] = value) : setThemeProp(theme[first])
  }

  theme.value && setThemeProp(theme.value)

  channel.port1.postMessage(
    JSON.stringify({ type: AppMessengerGetMessageNames.newTheme, theme: theme.value })
  )
}

const offHighlight = () => {
  channel.port1.postMessage(JSON.stringify({ type: AppMessengerGetMessageNames.themeHighlightOff }))
}

const onHighlightProp = (prop: string) => {
  channel.port1.postMessage(
    JSON.stringify({ type: AppMessengerGetMessageNames.themeHighlightOn, element: prop })
  )
}
/**
 * обработка загрузки темы
 * @param uploadedTheme
 */
const setNewTheme = (uploadedTheme: Theme) => {
  theme.value = uploadedTheme
  channel.port1.postMessage(
    JSON.stringify({ type: AppMessengerGetMessageNames.newTheme, theme: uploadedTheme })
  )
}
</script>
