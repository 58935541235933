<template>
  <div class="flex flex-col font-medium">
    <template v-if="showOneDate">
      <template v-if="isOnlyOneSeance">
        <span>
          <span class="font-normal text-text-secondary">
            {{ firstSeanceDayOfWeek === 1 ? 'во' : 'в' }}
            <span :class="firstSeanceDayOfWeek > 4 && 'text-text-primary'">{{
              weekDaysHandbook[firstSeanceDayOfWeek]
            }}</span></span>&nbsp;<UiDate
            :value="firstSeance.beginsAt"
            :template="getTemplate(firstSeance)"
          />,&nbsp;<span class="font-normal text-text-secondary">в</span>&nbsp;<UiDate
            :value="firstSeance.beginsAt"
            template="HH:mm"
          />
        </span>
      </template>
      <template v-else>
        <UiDate :value="firstSeance.beginsAt" :template="getTemplate(firstSeance)" />
      </template>
    </template>
    <template v-else-if="!isOneMonthSeances && eventMonths.length <= 3">
      <ul class="">
        <li
          v-for="(seance, seanceIndex) in seancesByEventMonths"
          :key="seance.id"
          :class="{ 'first:capitalize': capitalizeFirst }"
          class="relative bottom-0 right-0 inline"
        >
          <span class="inline-flex">
            <UiDate :value="seance.beginsAt" :template="getTemplate(seance)" />
            <span v-if="seanceIndex + 1 < seancesByEventMonths.length"> ,&nbsp;</span>
          </span>
        </li>
      </ul>
    </template>
    <template v-else-if="eventMonths.length > 3">
      <div class="">
        <UiDate :value="firstSeance.beginsAt" :template="getTemplate(firstSeance)" class="inline" />
        -
        <UiDate
          :value="seances[seances.length - 1].beginsAt"
          :template="getTemplate(seances[seances.length - 1])"
          class="inline"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { getDay, getMonth, isSameDay, isSameYear } from 'date-fns'
import type { Nullable } from 'ts-helpers'
import { computed } from 'vue'
import type { Seance } from '@/5_entities/Seance'
import { UiDate } from '@/6_shared/ui'

type PropType = {
  isOnlyOneSeance: boolean
  firstSeance: Seance
  seances: Seance[]
  eventMonths: number[]
  capitalizeFirst?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  capitalizeFirst: false
})
const firstSeanceDayOfWeek = computed(() => getDay(props.firstSeance.beginsAt))

/*
 DOC: https://youtrack.intickets.ru/issue/A-62/Logika-formirovaniya-opisaniya-u-najdennyh-zapisej-v-poiskovoj-vydache
✅
для мероприятия один сеанс в определённое время - формат: дата, месяц,время.
Пример: 1 сентября 18:00 , 1 сентября 18:00 (2025)✅

конкретный день (много сеансов, в один день)- формат: дата, сокращённый месяц.
Пример: 1 сентября, 1 сентября 2025✅

несколько разных дней (разные сеансы в разные дни, но один месяц) - формат: полный месяц.
Пример: сентябрь, сентябрь 2025✅

Идёт в разные месяцы не больше 3x разных месяцев
Если, не больше 3х месяцев в котором идёт мероприятие, то выводим название всех месяцев (не важно подряд они или в разнобой)
пример:✅
июль, сентябрь
июль, август, сентябрь
январь 2025, март 2025

Идёт в разные месяцы > 3x разных месяцев)
Если > 3x месяцев в котором идёт мероприятие, то выводим первый и последний месяц через дефис.
пример:✅
июль - январь
сентябрь - февраль 2025
январь 2025 - ноябрь 2025
 */

const showOneDate = computed(
  () =>
    props.isOnlyOneSeance ||
    isOneMonthAndOneDaySeances.value ||
    (isOneMonthSeances.value && !isOneMonthAndOneDaySeances.value)
)

// все сеансы идут в один месяц
const isOneMonthSeances = computed(() => {
  if (props.isOnlyOneSeance) return false

  let currentMonth: Nullable<number> = null

  for (const seance of props.seances) {
    const seanceMonth = getMonth(seance.beginsAt)

    if (currentMonth && seanceMonth !== currentMonth) return false

    currentMonth = seanceMonth
  }

  return true
})

//все сеансы идут в один месяц и в один день
const isOneMonthAndOneDaySeances = computed(() => {
  if (!isOneMonthSeances.value) return false

  let currentDay: Nullable<number> = null

  for (const seance of props.seances) {
    const seanceDay = getDay(seance.beginsAt)

    if (currentDay && currentDay !== seanceDay) return false

    currentDay = seanceDay
  }

  return true
})

// сеансы, выбранные по месяцам мероприятия
const seancesByEventMonths = computed((): Seance[] => {
  const months = [...props.eventMonths]

  return props.seances.reduce((acc, seance) => {
    if (months.includes(getMonth(seance.beginsAt))) {
      months.shift()

      acc.push(seance)
    }

    return acc
  }, [] as Seance[])
})

const getTemplate = (seance: Seance) => {
  const isOneDayEvent =
    props.isOnlyOneSeance &&
    props.firstSeance?.endsAt &&
    isSameDay(props.firstSeance.beginsAt, props.firstSeance.endsAt)
  const isAnotherYearEvent = !isSameYear(seance.beginsAt, new Date())

  if (isOneDayEvent) return `d MMMM${isAnotherYearEvent ? ' yyyy' : ''}`
  if (isOneMonthAndOneDaySeances.value) return `d MMMM${isAnotherYearEvent ? ' yyyy' : ''}`
  if (isOneMonthSeances.value && !isOneMonthAndOneDaySeances.value)
    return `LLLL${isAnotherYearEvent ? ' yyyy' : ''}`
  if (props.eventMonths.length > 1) return `LLLL ${isAnotherYearEvent ? 'yyyy' : ''}`

  return `d MMMM${isAnotherYearEvent ? ' yyyy' : ''}`
}

const weekDaysHandbook = [
  'понедельник',
  'вторник',
  'среду',
  'четверг',
  'пятницу',
  'субботу',
  'воскресенье'
]
</script>
