export enum SessionStorageNames {
  enableThemeConfigurator = 'enableThemeConfigurator',
  userId = 'userId'
}

export const useSessionStorage = () => {
  const set = (key: SessionStorageNames, value: string) => {
    sessionStorage.setItem(key, value)
  }

  const get = (key: SessionStorageNames) => {
    const value = sessionStorage.getItem(key)

    if (value === 'false') return false

    return value
  }

  return {
    set,
    get
  }
}
