<template>
  <main class="relative py-5">
    <CategoryItemHeader
      :date="datepickerValue"
      :value="category?.name"
      :loading="!categoryInitialized"
      @update:date="onDateUpdate"
    />
    <CategoryResults
      v-if="!categoryInitialized || category?.repertoire.length"
      class="mt-5"
      :value="category"
      :loading="!categoryInitialized"
      hide-header
    />
    <UiEmptySearch
      v-else-if="categoryInitialized"
      class="absolute h-full justify-center inset-center"
    />

    <UiEternalLoading
      v-if="categoryInitialized && !allEventsLoaded"
      :handler="loadMoreEvents"
      :all-loaded="allEventsLoaded"
    />
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { CategoryItemHeader, CategoryResults } from '@/3_widgets/Category'
import { type CategoryFilters, useCategory } from '@/5_entities/Category'
import { usePageFilters } from '@/6_shared/composables'
import { UiEmptySearch, UiEternalLoading } from '@/6_shared/ui'

const route = useRoute()
const { filters, updateDateFilters, datepickerValue } = usePageFilters<CategoryFilters>(5)

const onDateUpdate = (value: Undefinable<Date | Date[]>) => {
  updateDateFilters(value)
  loadCategory()
}

const { category, categoryInitialized, allEventsLoaded } = storeToRefs(useCategory())
const { getCategory, loadNextEventsPage } = useCategory()

const loadCategory = () => getCategory(Number(route.params.id), filters.value)
const loadMoreEvents = () => loadNextEventsPage(filters.value)

onBeforeMount(() => {
  loadCategory()
})
</script>
