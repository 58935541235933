<template>
  <div
    v-if="venues.length"
    class="w-fit text-pretty break-words text-left text-xs text-text-secondary"
  >
    <RouterLink v-if="venues.length === 1" :to="getLink(venues[0])" custom v-slot="{ navigate }">
      <UiButton
        v-if="type === 'link'"
        text
        class="text-left text-xs text-text-secondary"
        @click.stop="navigate"
      >
        {{ venues[0].title }}
      </UiButton>
      <p v-else class="text-left text-text-secondary">
        {{ venues[0].title }}
      </p>
    </RouterLink>
    <p v-else>в {{ toPlural(venues.length, 'месте', 'местах', 'местах') }}</p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { Venue } from '@/5_entities/Venue'
import { toPlural } from '@/6_shared/lib'
import { UiButton } from '@/6_shared/ui'
import type { Event } from '../../model'

type PropType = {
  value: Event
  type?: 'link' | 'text'
}

const props = withDefaults(defineProps<PropType>(), {
  type: 'link'
})

const venues = computed(() => {
  const seancesWithVenue = props.value.seances.filter((seance) => !!seance.venue)

  return seancesWithVenue.reduce((acc, seance) => {
    if (!acc.find((item) => item.id === seance.venue!.id)) acc.push(seance.venue!)

    return acc
  }, [] as Venue[])
})

const getLink = (venue: Venue) => ({ name: 'VenueView', params: { id: venue.id } })
</script>
