import debounce from 'lodash/debounce.js'
import { storeToRefs } from 'pinia'
import { useSearch } from '@/5_entities/Search'
import { getMilliseconds } from '@/6_shared/lib'

export const useSearchForEvents = () => {
  const { searchString } = storeToRefs(useSearch())
  const { search, resetSearch } = useSearch()
  const MIN_SEARCH_LENGTH = 2
  const SEARCH_DEBOUNCE = getMilliseconds.inSeconds(0.5)

  const onSearchInput = debounce(function () {
    if (!searchString.value) return resetSearch()

    if (searchString.value.length < MIN_SEARCH_LENGTH) return

    search(searchString.value)
  }, SEARCH_DEBOUNCE)

  return {
    searchString,
    onSearchInput
  }
}
