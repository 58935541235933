<template>
  <div class="border-b">
    <p class="font-bold">{{ title }}:</p>
    <ul class="flex flex-col">
      <li
        v-for="(shadow, shadowIndex) in shadowPropValues"
        :key="'shadow' + shadowIndex"
        class="border-b border-dotted py-2 last:border-0"
      >
        <header class="flex items-center justify-between">
          Часть {{ shadowIndex + 1 }}:
          <UiButton
            v-if="shadowPropValues.length > 1"
            severity="tertiary"
            size="small"
            class="!bg-error text-fivefold"
            @click="deleteShadowPart(shadowIndex)"
          >
            Удалить
          </UiButton>
        </header>
        <form @submit.prevent>
          <fieldset class="grid grid-cols-2 gap-2">
            <div v-for="shadowParam in 4" :key="'shadowParam' + shadowParam">
              <UiInput
                :model-value="shadow[shadowParam - 1]"
                :placeholder="shadowLegend[shadowParam - 1]"
                @change="onShadowParamChange($event, shadowIndex, shadowParam - 1)"
              >
                <template #label> {{ shadowLegend[shadowParam - 1] }} </template>
              </UiInput>
            </div>
          </fieldset>
          <fieldset class="mt-2">
            <UiColorPicker
              :model-value="shadow[4]"
              @update:model-value="onShadowParamChange($event, shadowIndex, 4, true)"
            >
              <template #label> {{ shadowLegend[4] }}: {{ shadow[4] }}<br /> </template>
            </UiColorPicker>
          </fieldset>
        </form>
      </li>
    </ul>
    <UiButton class="my-2 w-full" size="small" @click="addShadowPart">
      Добавить новую тень
    </UiButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { UiButton, UiColorPicker, UiInput } from '@/6_shared/ui'

type PropType = {
  title: string
  value: string
}
type EmitType = {
  (e: 'update:value', shadows: string): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const shadowLegend = ['offset-x', 'offset-y', 'blur-radius', 'spread-radius', 'color']

const shadowPropValues = computed(() => {
  return props.value.split(', ').map((item) => item.split(' '))
})

/**
 * собирает тени в верном формате и эмитит
 * @param shadows
 */
const emitUpdatedShadows = (shadows: string[][]) => {
  const joinedByParamsShadows = shadows.map((item) => item.join(' '))

  emit('update:value', joinedByParamsShadows.join(', '))
}

/**
 * нормализовывает параметры тени
 * @param value
 */
const normalizeValue = (value: string) => {
  value = value || '0px'

  if (!isNaN(Number(value))) {
    value += 'px'
  }

  return value
}

/**
 * обработка смены параметра тени
 * @param newValue
 * @param shadowIndex
 * @param paramNumber
 * @param isColor
 */
const onShadowParamChange = (
  newValue: string,
  shadowIndex: number,
  paramNumber: number,
  isColor = false
) => {
  newValue = (isColor ? newValue || 'rgba(0,0,0,1)' : normalizeValue(newValue)).replace(/ /g, '')
  const shadows = [...shadowPropValues.value]
  shadows[shadowIndex][paramNumber] = newValue

  emitUpdatedShadows(shadows)
}

/**
 * создает дефолтную тень
 */
const createShadow = () => '0 0 0 0 rgba(0,0,0,1)'.split(' ')

/**
 * добавляет новую часть тени
 */
const addShadowPart = () => {
  const newShadow = createShadow()
  const shadows = [...shadowPropValues.value]

  shadows.push(newShadow)

  emitUpdatedShadows(shadows)
}
/**
 * удаляет часть тени
 */
const deleteShadowPart = (deleteIndex: number) => {
  const shadows = [...shadowPropValues.value]

  shadows.splice(deleteIndex, 1)

  emitUpdatedShadows(shadows)
}
</script>
