<template>
  <section class="px-4 py-2">
    <ThemeConstructorPropertyHeader v-model:open="open" :title="title" />
    <div v-show="open">
      <template
        v-for="([themeKey, themeValue], _valueKey, index) in Object.entries(value)"
        :key="index"
      >
        <ChangeThemeConstructorShadows
          v-if="isPlainObject(themeValue)"
          :value="themeValue"
          :title="themeKey"
          @change-shadow="changeThemeShadowChild($event, themeKey)"
          @mouseenter="onChildMouseenter($event, themeKey)"
          @mouseleave="emit('mouseleave')"
        />
        <EditThemeShadow
          v-else
          :title="themeKey"
          :value="themeValue"
          class="mb-2"
          @update:value="onShadowsUpdate($event, themeKey)"
          @mouseenter="onMouseEnter(themeKey)"
          @mouseleave="emit('mouseleave')"
        />
      </template>
    </div>
  </section>
</template>

<script lang="ts" setup>
import isPlainObject from 'lodash/isPlainObject.js'
import { ref } from 'vue'
import {
  type ThemeConfigurationChangeShadowEmit,
  ThemeConstructorPropertyHeader
} from '@/5_entities/Configuration'
import EditThemeShadow from './EditThemeShadow.vue'

type PropType = {
  title?: string
  value: Record<string, string> | string
}
type EmitType = {
  (e: 'changeShadow', obj: ThemeConfigurationChangeShadowEmit): void
  (e: 'mouseleave'): void
  (e: 'mouseenter', title: string): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()

const open = ref(false)

const onShadowsUpdate = (shadows: string, themeKey: string) => {
  emit('changeShadow', { shadow: shadows, themeKey })
}
const changeThemeShadowChild = (
  { shadow, themeKey }: ThemeConfigurationChangeShadowEmit,
  newKey: string
) => {
  emit('changeShadow', { shadow, themeKey: `${newKey}-${themeKey}` })
}

const onMouseEnter = (title: string) => {
  emit('mouseenter', title)
}
const onChildMouseenter = (childTitle: string, title: string) => {
  emit('mouseenter', `${title}-${childTitle}`)
}
</script>
