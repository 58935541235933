import { ref } from 'vue'
import { appMessenger } from '@/5_entities/Configuration'
import { seanceService, type Seance } from '@/5_entities/Seance'

export const useOpenSeanceWidget = () => {
  const showWidget = ref(false)
  const loading = ref(false)
  const widgetUrl = ref('')

  const openWidget = async (seanceId: Seance['id']) => {
    let timeout: NodeJS.Timeout | null = null

    try {
      resetErrors()

      timeout = setTimeout(() => {
        loading.value = true
      }, 300)
      showWidget.value = true
      widgetUrl.value = (await seanceService.getWidget(seanceId)).url
      appMessenger.widget.open()
    } catch (error: any) {
      if (error.code && error.code === 404) {
        notFoundError.value = true
      } else {
        unavailableError.value = true
      }
    } finally {
      timeout && clearTimeout(timeout)
      loading.value = false
    }
  }

  //errors
  const notFoundError = ref(false)
  const unavailableError = ref(false)
  const resetErrors = () => {
    notFoundError.value = false
    unavailableError.value = false
  }

  return {
    showWidget,
    loading,
    widgetUrl,
    openWidget,
    //errors
    notFoundError,
    unavailableError
  }
}
