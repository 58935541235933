<template>
  <ul :class="{ 'max-w-full lg:max-w-[184px] xl:max-w-[208px]': !isNearest }">
    <li class="relative z-10 w-full">
      <RouterLink
        custom
        v-slot="{ navigate, href }"
        :to="{ name: 'SeanceView', params: { id: firstSeance.id } }"
      >
        <a
          :href="href"
          :class="[
            'flex max-w-full flex-row overflow-hidden rounded-xl border border-card-primary bg-primary text-left shadow-card_primary_hover transition-all ',
            'hover:border-card-primary-hover lg:hover:-translate-y-1 lg:hover:shadow-card_primary_hover',
            'lg:focus:border-card-primary-focus lg:focus:shadow-card_primary_hover',
            'lg:max-w-[208px] lg:flex-col lg:shadow-card_primary',
            isNearest && 'lg:w-[208px]',
            !firstSeance.ticketsLeft && 'pointer-events-none'
          ]"
          v-bind="$attrs"
          @click.prevent="navigate()"
        >
          <header
            :class="[
              'gradient-card w-min whitespace-nowrap bg-cover p-2 text-center',
              'lg:mb-2 lg:w-full lg:p-0'
            ]"
          >
            <h2
              v-if="isLgAndUp && isNearest"
              class="hidden px-3 py-1 text-xs text-text-primary lg:block"
            >
              ближайшее мероприятие
            </h2>
            <div
              v-if="(!isNearest && isLgAndUp) || isLgAndDown"
              class="flex w-full flex-row flex-wrap gap-x-1.5 lg:items-center lg:p-3"
            >
              <div
                class="flex w-full flex-col items-start lg:w-fit lg:flex-row lg:items-center lg:gap-x-1.5"
              >
                <UiDate
                  :value="firstSeance.beginsAt"
                  :template="isLgAndUp ? 'd MMMM' : 'd'"
                  class="text-base font-semibold"
                />
                <UiDate
                  v-if="isLgAndDown"
                  :value="firstSeance.beginsAt"
                  template="MMMM"
                  class="text-xs font-semibold lg:text-base"
                />
                <UiDate
                  v-if="isLgAndUp && !isSameYear(new Date(), firstSeance.beginsAt)"
                  :value="firstSeance.beginsAt"
                  template="yyyy"
                  class="pt-1 text-xs text-text-fivefold"
                />
              </div>
              <div class="flex gap-1">
                <UiDate
                  v-if="isLgAndDown && !isSameYear(new Date(), firstSeance.beginsAt)"
                  :value="firstSeance.beginsAt"
                  template="yyyy"
                  class="pt-1 text-xs text-text-fivefold"
                />
                <UiDate
                  :value="firstSeance.beginsAt"
                  :template="isLgAndUp ? 'EEEE' : 'EEEEEE'"
                  :class="{ 'text-text-primary': isWeekend(firstSeance.beginsAt) }"
                  class="pt-1 text-xs font-normal text-text-fivefold"
                />
              </div>
            </div>
          </header>
          <section class="px-2 py-3.5 lg:mb-5 lg:px-3 lg:py-0">
            <template v-if="isNearest && isLgAndUp">
              <div class="mb-1 flex flex-wrap items-center gap-x-1.5">
                <div class="flex items-center gap-x-1.5 text-start">
                  <UiDate :value="firstSeance.beginsAt" template="d MMMM" class="font-semibold" />
                  <UiDate
                    v-if="!isSameYear(new Date(), firstSeance.beginsAt)"
                    :value="firstSeance.beginsAt"
                    template="yyyy"
                    class="pt-1 text-xs text-text-fivefold"
                  />
                </div>
                <UiDate
                  :value="firstSeance.beginsAt"
                  template="EEEE"
                  :class="{ 'text-text-primary': isWeekend(firstSeance.beginsAt) }"
                  class="pt-1 text-xs font-normal text-text-fivefold"
                />
              </div>
            </template>
            <div class="flex gap-1">
              <UiDate
                :value="firstSeance.beginsAt"
                template="HH:mm"
                class="mb-1 block text-left font-semibold"
              />
              <template v-if="isLgAndDown">
                <p v-if="firstSeance.ticketsLeft" class="text-center font-semibold text-primary">
                  <span class="text-xs font-medium">от</span>
                  {{ toRub(firstSeance.priceRange.min) }}
                </p>
                <p v-else class="text-center font-semibold text-text-secondary">распродано</p>
              </template>
            </div>
            <template v-if="firstSeance.venue">
              <p v-if="firstSeance.venue" class="text-xs">
                {{ firstSeance.venue.title }}
              </p>
              <p v-if="isLgAndUp" class="text-xs text-text-fivefold">
                {{ firstSeance.venue.cityName }}
              </p>
            </template>
          </section>
          <footer class="ml-auto mr-3 flex lg:mx-3 lg:block lg:pb-3">
            <template v-if="isLgAndUp">
              <UiSplitter class="mb-4" />
              <p v-if="firstSeance.ticketsLeft" class="text-center font-semibold text-primary">
                от {{ toRub(firstSeance.priceRange.min) }}
              </p>
              <p v-else class="text-center font-semibold text-text-secondary">распродано</p>
            </template>
            <template v-else>
              <UiIcon name="chevron-down" class="my-auto size-3 -rotate-90 text-icon-quaternary" />
            </template>
          </footer>
        </a>
      </RouterLink>
    </li>
    <li
      v-for="(seance, index) in anotherSeances"
      :key="seance.id"
      class="relative"
      :style="{ zIndex: 9 - index }"
    >
      <RouterLink
        custom
        v-slot="{ navigate, href }"
        :to="{ name: 'SeanceView', params: { id: seance.id } }"
      >
        <a
          :href="href"
          :class="[
            '-mt-4 flex w-full flex-col overflow-hidden rounded-b-xl border border-card-primary bg-primary px-3 pb-3 pt-6 text-left shadow-card_primary transition-all',
            'hover:translate-y-1 hover:border-card-primary-hover hover:shadow-card_primary_hover',
            'focus:border-card-primary-focus focus:shadow-card_primary_hover',
            !seance.ticketsLeft && 'pointer-events-none'
          ]"
          v-bind="$attrs"
          @click.prevent="navigate()"
        >
          <p v-if="index === 0" class="mb-1 text-xs text-text-fivefold">или в другое время</p>
          <div class="flex gap-2">
            <UiDate :value="seance.beginsAt" template="HH:mm" class="font-semibold" />
            <p v-if="seance.ticketsLeft" class="text-center font-semibold text-primary">
              от {{ toRub(seance.priceRange.min) }}
            </p>
            <p v-else class="font-semibold text-text-secondary">распродано</p>
          </div>
          <p v-if="seance.venue" :title="seance.venue.title" class="line-clamp-1 text-xs">
            {{ seance.venue.title }}
          </p>
        </a>
      </RouterLink>
    </li>
    <li v-if="hasMoreSeances && edgeSeances" class="mt-1">
      <SeanceTimetableButton
        :event-id="eventId"
        type="edge"
        :edge-seances="edgeSeances"
        @open="emit('openTimetable', $event)"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { isWeekend, isSameYear } from 'date-fns'
import { computed } from 'vue'
import type { Event } from '@/5_entities/Event'
import { toRub } from '@/6_shared/lib'
import { useBreakpoint } from '@/6_shared/lib'
import { UiDate, UiIcon, UiSplitter } from '@/6_shared/ui'
import type { Seance } from '../../model'
import SeanceTimetableButton from '../SeanceTimetableButton/SeanceTimetableButton.vue'

type PropType = {
  value: Seance[]
  type?: 'nearest' | 'base'
  eventId: Event['id']
}
type EmitType = {
  (e: 'openTimetable', eventId: Event['id']): void
}

const props = withDefaults(defineProps<PropType>(), {
  type: 'base'
})
const emit = defineEmits<EmitType>()

const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()

const isNearest = computed(() => props.type === 'nearest')
const firstSeance = computed(() => props.value[0])
const anotherSeances = computed(() =>
  props.value.filter((_item, index) => index !== 0 && index < 3)
)
const hasMoreSeances = computed(() => props.value.length > 3)
const edgeSeances = computed(() => {
  if (!hasMoreSeances.value) return

  const availableSeances = props.value.filter((_item, index) => index >= 3)

  return availableSeances.length > 1
    ? [availableSeances[0], availableSeances[availableSeances.length - 1]]
    : [availableSeances[0]]
})
</script>
