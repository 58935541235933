import type { RouteRecordRaw } from 'vue-router'

export const CompilationViewRoute: RouteRecordRaw = {
  path: '/compilation/:slug',
  name: 'CompilationView',
  component: () => import('../ui/CompilationView.vue'),
  meta: {
    layout: 'empty'
  }
}
