<template>
  <main class="relative">
    <UiRoundedHeader sticky class="flex items-center bg-secondary">
      <GoBack v-if="venue" class="mr-3" />
      <UiTitle v-if="venue" class="line-clamp-1">{{ venue.title }}</UiTitle>
    </UiRoundedHeader>
    <VenueRepertoire :value="venue" :loading="!initialized" />
  </main>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { VenueRepertoire } from '@/3_widgets/Venue'
import { GoBack } from '@/4_features/Navigation'
import { useVenue } from '@/5_entities/Venue'
import { UiRoundedHeader, UiTitle } from '@/6_shared/ui'

const { getVenue } = useVenue()
const { venue, initialized } = storeToRefs(useVenue())

onBeforeMount(() => {
  const { params } = useRoute()

  if (!venue.value || Number(params.id as string) !== venue.value.id) getVenue(Number(params.id))
})
</script>
