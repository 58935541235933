<template>
  <VueEternalLoading :position="position" :margin="margin" :load="loadHandler">
    <template #no-more>
      <div />
    </template>

    <template #loading>
      <slot name="loading">
        <p class="flex justify-center pt-8 text-text-quaternary">
          <svg
            class="animate-spin"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2546 15.7155C12.4501 15.3154 13.0588 17.1334 11.863 17.5336C10.774 17.8995 9.61619 18.052 8.46943 17.9844C3.50967 17.6915 -0.277049 13.4295 0.0159156 8.46971C0.30888 3.50993 4.57082 -0.277051 9.53057 0.0159148C14.4903 0.308881 18.2771 4.57085 17.9841 9.53063C17.9606 9.93149 17.9079 10.3525 17.8302 10.7464C17.5881 11.9865 15.7023 11.6187 15.9444 10.3783C16.0086 10.0535 16.049 9.74853 16.0684 9.41804C16.2988 5.51917 13.3171 2.16191 9.41798 1.9318C5.51914 1.70147 2.1619 4.6832 1.93179 8.5823C1.70146 12.4812 4.68294 15.8384 8.58202 16.0685C9.49164 16.1222 10.3905 16.0057 11.2546 15.7155Z"
              fill="currentColor"
            />
          </svg>
        </p>
      </slot>
    </template>

    <template #error="{ retry }">
      <div class="flex flex-col items-center py-5">
        <UiButton text class="text-base font-semibold text-text-primary" @click="retry">
          ой, не смогли загрузить, попробовать еще раз
        </UiButton>
      </div>
    </template>
  </VueEternalLoading>
</template>

<script lang="ts" setup>
import { VueEternalLoading, type LoadAction, type Position } from '@ts-pro/vue-eternal-loading'
import UiButton from './UiButton.vue'

type PropType = {
  handler: (() => Promise<void>) | (() => void)
  allLoaded: boolean
  position?: Position
  margin?: string
}

const props = withDefaults(defineProps<PropType>(), {
  position: 'default',
  margin: '200px'
})

const loadHandler = async ({ loaded, error, noMore }: LoadAction) => {
  try {
    await props.handler()
    props.allLoaded ? noMore() : loaded()
  } catch {
    error()
  }
}
</script>
