<template>
  <div>
    <ul class="flex flex-col gap-4">
      <template v-if="!loading && value">
        <SearchResultsCategory
          v-for="categoryResults in value"
          :key="categoryResults.id"
          :value="categoryResults"
          :class="[
            'before:absolute before:bottom-0 before:left-4 before:h-px before:w-[calc(100%_-_2rem)] before:bg-secondary before:content-[\'\'] last:before:hidden',
            'relative pb-3'
          ]"
        />
      </template>
      <template v-else-if="loading">
        <SearchResultsCategorySkeleton />
      </template>
    </ul>
    <UiEternalLoading
      v-if="!loading && value"
      :all-loaded="allLoaded"
      :handler="() => emit('next-page')"
    />
  </div>
</template>

<script lang="ts" setup>
import { type SearchResults } from '@/5_entities/Search'
import { UiEternalLoading } from '@/6_shared/ui'
import SearchResultsCategory from './SearchResultsCategory.vue'
import SearchResultsCategorySkeleton from './Skeleton/SearchResultsCategorySkeleton.vue'

type PropType = {
  value?: SearchResults
  loading?: boolean
  allLoaded: boolean
}

type EmitType = {
  (e: 'next-page'): void
}

withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})

const emit = defineEmits<EmitType>()
</script>
