<template>
  <UiContainer class="!px-0 lg:!px-7">
    <header class="px-4 lg:px-0">
      <UiButton
        tag="RouterLink"
        :to="{ name: 'CompilationView', params: { slug: value.slug } }"
        link
        text
        class="mb-2 flex w-full items-start justify-between !text-text-main"
      >
        <UiTitle severity="h2">{{ value.title }}</UiTitle>
      </UiButton>
    </header>
    <ul
      :class="[
        'scrollbar-hidden flex gap-3 overflow-y-hidden overflow-x-scroll px-4 lg:px-0',
        {
          'grid-cols-4 lg:grid-cols-5': size === 'small',
          'grid-cols-3 lg:grid-cols-4': size === 'big'
        },
        !isMobile && 'grid gap-6 overflow-x-hidden '
      ]"
    >
      <RouterLink
        v-for="item in items"
        :key="item.object.id"
        :to="getCompilationItemLink(item)"
        custom
        v-slot="{ href, navigate }"
      >
        <li
          class="w-full lg:min-w-px"
          :class="{
            'col-span-1  min-w-2/3 sm:min-w-1/3': size === 'big',
            'col-span-1 min-w-1/2 sm:min-w-1/5': size === 'small'
          }"
        >
          <a :href="href" @click.prevent="navigate()">
            <Component
              :is="compilationComponentsMap[item.type]"
              :value="item.object as Event"
              size="big"
            />
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-if="previewItems.length"
        :to="{ name: 'CompilationView', params: { slug: value.slug } }"
        custom
        v-slot="{ href, navigate }"
      >
        <li
          :class="{
            'min-w-2/3 sm:min-w-1/3': size === 'big',
            'min-w-1/2 sm:min-w-1/5': size === 'small'
          }"
          class="flex aspect-default h-fit w-full rounded-lg border border-secondary px-2 py-3 lg:hidden lg:min-w-px"
        >
          <a :href="href" class="flex w-full flex-col" @click.prevent="navigate()">
            <ul class="flex overflow-hidden rounded">
              <li
                v-for="(preview, index) in previewItems"
                :key="preview.object.id"
                :style="{ zIndex: 1 + index }"
                class="-ml-8 flex aspect-default w-full max-w-[calc(33%_+_32px)] justify-center overflow-hidden bg-light shadow-compilations_preview first:ml-0"
              >
                <UiPicture
                  :src="preview.object.image"
                  :alt="preview.object.title"
                  :options="{ height: 140 }"
                  class="max-h-full max-w-full object-contain object-center"
                >
                  <template #default-image>
                    <UiBackupImage
                      :letter="preview.object.title[0]"
                      :alt="preview.object.title"
                      class="bg-light"
                    />
                  </template>
                </UiPicture>
              </li>
            </ul>
            <p class="mt-auto w-full text-center text-xs font-medium">открыть подборку</p>
          </a>
        </li>
      </RouterLink>
    </ul>
    <div class="slider-gradient absolute right-0 top-0 z-10 block h-full w-5 lg:hidden" />
  </UiContainer>
</template>

<script lang="ts" setup>
import { isMobile } from '@basitcodeenv/vue3-device-detect'
import { computed } from 'vue'
import { type Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiBackupImage, UiButton, UiContainer, UiPicture, UiTitle } from '@/6_shared/ui'
import { getCompilationItemLink, compilationComponentsMap } from '../../lib'
import type { Compilation } from '../../model'

type PropType = {
  value: Compilation
  size?: 'big' | 'small'
}

const props = withDefaults(defineProps<PropType>(), {
  size: 'big'
})
const {
  lg: [isLgAndUp]
} = useBreakpoint()
const MAX_ITEMS_BIG = 4
const MAX_ITEMS_SMALL = 5

const items = computed(() =>
  props.value.items.filter((_item, index) =>
    isLgAndUp.value
      ? index < (props.size === 'big' ? MAX_ITEMS_BIG : MAX_ITEMS_SMALL)
      : index < MAX_ITEMS_SMALL
  )
)
const previewItems = computed(() => {
  const startIndex = isLgAndUp.value
    ? props.size === 'big'
      ? MAX_ITEMS_BIG
      : MAX_ITEMS_SMALL
    : MAX_ITEMS_SMALL

  return props.value.items.filter((_item, index) => index >= startIndex && index < startIndex + 3)
})
</script>
