import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref, shallowRef } from 'vue'
import { usePageLoading } from '@/6_shared/composables'
import type { Pagination } from '@/6_shared/model'
import { categoryService } from '../api'
import { type Category, type CategoryDTO, type CategoryFilters, CategoryAdapter } from '../model'

export const useCategory = defineStore('category', () => {
  //category
  const category = ref<Undefinable<Category>>(undefined)
  const setCategory = (categoryData: CategoryDTO) =>
    (category.value = new CategoryAdapter(categoryData))

  const { initialized: categoryInitialized, load: loadCategory } = usePageLoading()
  const getCategory = (id: number, filters?: CategoryFilters) => {
    loadCategory(async () => {
      const category = await categoryService.get(id, filters)
      setCategory(category)
    })
  }

  //events
  const pagination = computed((): Undefinable<Pagination> => category.value?.eventsPagination)
  const allEventsLoaded = computed(
    () => !!pagination.value && pagination.value.currentPage >= pagination.value.pagesCount
  )

  const loadNextEventsPage = async (filters: CategoryFilters) => {
    try {
      if (!category.value || !pagination.value) return

      const newEvents = await categoryService.loadEvents(category.value.id, {
        ...filters,
        pagination: {
          ...filters.pagination,
          currentPage: pagination.value.currentPage + 1
        }
      })

      category.value.addEventsNextPage && category.value.addEventsNextPage(newEvents.repertoire!)
    } catch (e) {
      console.log(e)
    }
  }

  //all categories
  const categories = shallowRef<Undefinable<Category[]>>(undefined)
  const setCategories = (categoriesData: CategoryDTO[]) => {
    categoriesData.sort((itemA, itemB) => itemB.rank - itemA.rank)
    categories.value =
      categoriesData
        .map((category) => new CategoryAdapter(category))
        .filter((item) => item.repertoire?.length) ?? []
  }

  const { initialized: categoriesInitialized, load: loadCategories } = usePageLoading()
  const getAllCategories = (filters?: CategoryFilters) => {
    loadCategories(
      async () => {
        categories.value = undefined
        const categoriesDTOs = await categoryService.getAll(filters)
        setCategories(categoriesDTOs)
      },
      (error: any) => {
        throw error
      }
    )
  }

  return {
    //category
    category,
    getCategory,
    categoryInitialized,
    //events
    pagination,
    allEventsLoaded,
    loadNextEventsPage,
    //all categories
    categories,
    categoriesInitialized,
    getAllCategories
  }
})
