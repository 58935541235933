<template>
  <div
    :class="[containerStyle[size], bordered && 'rounded-xl shadow-card_primary']"
    class="group w-full"
  >
    <div
      class="relative flex aspect-default h-fit items-center justify-center overflow-hidden rounded-lg border border-secondary bg-tertiary"
      :class="[imageStyles[size], bordered && 'rounded-b-none rounded-t-xl']"
    >
      <UiPicture
        v-for="image in 2"
        :key="'image' + image"
        :src="value.image"
        :alt="value.title"
        :options="{ height: 190 * 1.5, enlarge: 1 }"
        :img-classes="image > 1 ? 'object-cover' : ''"
        :class="
          image > 1
            ? 'absolute z-0 size-full object-cover opacity-20 blur-lg inset-center'
            : 'relative z-10 h-full max-h-full max-w-full object-contain transition ease-out group-hover:scale-105'
        "
        lazy
      >
        <template v-if="image < 2" #default-image>
          <UiBackupImage :letter="value.title[0]" :alt="value.title" />
        </template>
      </UiPicture>

      <UiBadge
        v-if="value.minPrice && size === 'big'"
        severity="secondary"
        position-x="right"
        position-y="bottom"
        class="z-10 m-3"
      >
        от {{ toRub(value.minPrice) }}
      </UiBadge>
    </div>
    <div
      class="flex flex-col justify-between pt-2"
      :class="[
        infoContainerStyles[size],
        bordered && 'rounded-b-xl border border-card-primary border-t-transparent px-3 pb-4 '
      ]"
    >
      <UiTitle
        :severity="titleSeverity[size]"
        :class="titleStyles[size]"
        class="text-pretty break-words !font-medium leading-5 text-text-main"
      >
        {{ value.title }}
        <span class="text-xs font-normal text-text-secondary">{{ value.ageCategory }}</span>
      </UiTitle>
      <template v-if="value.seances.length">
        <EventDate class="mb-auto mt-2.5 !text-xs" :value="value" />
        <EventVenue v-if="!hideVenue" class="mt-1" :value="value" type="text" />
        <div v-if="!hideCategory && value.category" class="text-sm text-text-secondary">
          {{ value.category.name }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRub } from '@/6_shared/lib'
import { UiBackupImage, UiBadge, UiPicture, UiTitle } from '@/6_shared/ui'
import { type Event } from '../../model'
import EventDate from '../EventDate/EventDate.vue'
import EventVenue from '../EventVenue/EventVenue.vue'

type PropType = {
  value: Event
  hideVenue?: boolean
  hideCategory?: boolean
  size?: 'small' | 'big'
  bordered?: boolean
}

withDefaults(defineProps<PropType>(), {
  hideVenue: false,
  hideCategory: false,
  size: 'small',
  bordered: false
})

const containerStyle: Record<NonNullable<PropType['size']>, string> = {
  small: 'flex',
  big: 'flex flex-col'
}

const imageStyles: Record<NonNullable<PropType['size']>, string> = {
  small: 'mr-2 min-w-1/3 max-w-1/3',
  big: 'min-w-ful w-full'
}

const infoContainerStyles: Record<NonNullable<PropType['size']>, string> = {
  small: 'min-w-2/3',
  big: 'min-w-1'
}

const titleSeverity: Record<
  NonNullable<PropType['size']>,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
> = {
  small: 'h6',
  big: 'h5'
}

const titleStyles: Record<NonNullable<PropType['size']>, string> = {
  small: 'mb-1/2',
  big: ''
}
</script>
