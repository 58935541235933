<template>
  <section>
    <form @submit.prevent>
      <template v-for="(themeValue, key) in value" :key="key">
        <ChangeThemeConstructorColors
          v-if="themeValue && isColorProperty(key)"
          :title="key"
          :value="themeValue as Record<string, string>"
          @change-color="onColorChange($event, key)"
          @mouseenter="onColorMouseEnter($event, key)"
          @mouseleave="emit('offHighlight')"
        />
        <ChangeThemeConstructorShadows
          v-if="themeValue && isShadowProperty(key)"
          :title="key"
          :value="themeValue as Record<string, string>"
          @change-shadow="onShadowChange($event, key)"
          @mouseenter="onShadowMouseEnter($event)"
          @mouseleave="emit('offHighlight')"
        />
      </template>
    </form>
  </section>
</template>

<script lang="ts" setup>
import {
  ChangeThemeConstructorColors,
  ChangeThemeConstructorShadows
} from '@/4_features/Configuration'
import type {
  EditableThemeProperties,
  Theme,
  ThemeConfigurationChangeColorEmit,
  ThemeConfigurationChangePropEmit,
  ThemeConfigurationChangeShadowEmit
} from '@/5_entities/Configuration'

type PropType = {
  value: Theme
}
type EmitType = {
  (e: 'changeProp', obj: ThemeConfigurationChangePropEmit): void
  (e: 'highlightProp', prop: string): void
  (e: 'offHighlight'): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()

const onColorChange = (obj: ThemeConfigurationChangeColorEmit, title: string) => {
  const { color, themeKey } = obj

  emit('changeProp', { value: color, variable: `${title}-${themeKey}` })
}
const onShadowChange = (obj: ThemeConfigurationChangeShadowEmit, title: string) => {
  const { shadow, themeKey } = obj

  emit('changeProp', { value: shadow, variable: `${title}-${themeKey}` })
}

const isColorProperty = (themeProperty: EditableThemeProperties) => {
  return (
    themeProperty === 'colors' ||
    themeProperty === 'backgroundColor' ||
    themeProperty === 'borderColor'
  )
}
const isShadowProperty = (themeProperty: EditableThemeProperties) => {
  return themeProperty === 'boxShadow'
}

const createVariableName = (variable: string) => {
  return variable.replace(`-DEFAULT`, '')
}

const onColorMouseEnter = (variable: string, title: string) => {
  const createPrefix = () => {
    switch (title) {
      case 'colors':
        return 'text'
      case 'backgroundColor':
        return 'bg'
      case 'borderColor':
        return 'border'
    }
  }

  const name = `${createPrefix()}-${createVariableName(variable)}`

  emit('highlightProp', name)
}

const onShadowMouseEnter = (variable: string) => {
  const name = `shadow-${createVariableName(variable)}`

  emit('highlightProp', name)
}
</script>
