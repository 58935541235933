<template>
  <main class="relative">
    <SearchForEvents
      class="sticky inset-0 z-10 mb-3 !rounded-3xl border-b border-secondary bg-primary pb-6 pt-8 shadow-tertiary"
    />
    <SearchResults
      v-if="!searchIsPending"
      :value="showingItems"
      :loading="false"
      :all-loaded="!!allItemsIsVisible"
      @next-page="loadNextPage"
    />
    <UiEmptySearch v-if="!searchIsPending && isEmptyResults" class="mb-14 mt-6" />
    <UiLoader v-if="searchIsPending" class="absolute inset-0 z-0 size-full" />
    <!--    <SearchRecommendations-->
    <!--      v-if="showRecommendations && searchRecommendations"-->
    <!--      :value="searchRecommendations"-->
    <!--    />-->
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, provide } from 'vue'
import { SearchResults } from '@/3_widgets/Search'
import { SearchForEvents } from '@/4_features/Search'
import { type SearchResultsItem, useSavedSearch, useSearch } from '@/5_entities/Search'
import { usePageInfiniteLoading } from '@/6_shared/composables'
import { UiLoader, UiEmptySearch } from '@/6_shared/ui'

const { results, searchPage, searchIsPending, searchString } = storeToRefs(useSearch())
const { resetSearch } = useSearch()
const isEmptyResults = computed(() => results.value && !results.value.length)

// const { searchRecommendations } = storeToRefs(useSearch())
//const { getRecommendations } = useSearch()
// const showRecommendations = computed(() => {
//   return !searchIsPending.value && (isEmptyResults.value || !results.value)
// })

//onBeforeMount(() => getRecommendations())

const PAGE_SIZE_BY_CATEGORIES = 3
const { showingItems, loadNextPage, allItemsIsVisible } = usePageInfiniteLoading<SearchResultsItem>(
  PAGE_SIZE_BY_CATEGORIES,
  searchPage,
  results
)

// сохраняем поиск для странц категории поиска, мероприятия, площадки
const ROUTES_WITH_SAVED_SEARCH = ['SearchCategoryView', 'EventView', 'VenueView']
useSavedSearch(ROUTES_WITH_SAVED_SEARCH, resetSearch)

provide('searchString', searchString)
</script>
