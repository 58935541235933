<template>
  <div class="image-container relative size-full">
    <img
      :src="`/event-poster/${image}.svg`"
      :alt="alt"
      class="size-full object-cover transition ease-out group-hover:scale-105"
    />
    <p
      class="absolute inset-0 text-right font-bold leading-[64cqw] opacity-50 mix-blend-overlay transition ease-out group-hover:scale-105"
    >
      {{ letter }}
    </p>
  </div>
</template>

<script lang="ts" setup>
type PropType = {
  letter?: string
  alt?: string
}

withDefaults(defineProps<PropType>(), { letter: '', alt: '', index: 4 })

const availableImageIndexes = () => {
  const index = Math.round(Math.random() * 5)
  const arr = Array.from({ length: 5 }, (_, i) => i + 1)
  for (let i = 0; i <= index; i++) {
    arr.push(...Array.from({ length: 5 }, (_, i) => i + 1))
  }

  return arr
}
const arr = availableImageIndexes()
const image = arr[Math.floor(Math.random() * arr.length)]
</script>

<style scoped>
.image-container {
  container: image-container / inline-size;
}

.image-container p {
  font-size: 94cqw;
  line-height: 64cqw;
}
</style>
