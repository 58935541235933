<template>
  <div class="flex animate-pulse flex-col">
    <div class="mb-4">
      <div class="mb-2 h-5 w-1/2 rounded-lg bg-secondary" />
      <div class="mb-2 h-5 w-3/4 rounded-lg bg-secondary" />
      <div class="mb-2 h-5 w-3/4 rounded-lg bg-secondary" />
      <div class="mb-2 h-5 w-3/4 rounded-lg bg-secondary" />
    </div>
  </div>
</template>
