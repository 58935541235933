import { isSameDay } from 'date-fns'
import type { Seance } from '../model'

export const getSeancesByDay = (seances: Seance[]): Seance[][] => {
  return seances.reduce((acc, item) => {
    const alreadyHasDay = acc.findIndex(
      (days) => days[0] && isSameDay(days[0].beginsAt, item.beginsAt)
    )

    if (alreadyHasDay >= 0) {
      acc[alreadyHasDay].push(item)
    } else {
      acc.push([item])
    }

    return acc
  }, [] as Seance[][])
}
