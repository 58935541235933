import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref, shallowRef } from 'vue'
import type { CategoryShort } from '@/5_entities/Category'
import type { UserAgeInterval } from '@/5_entities/User'
import { getMilliseconds } from '@/6_shared/lib'
import { configurationService } from '../api'
import { userAgeIntervalsDefault } from '../config'
import { partnerKey } from '../lib'
import type { ConfigurationDTO } from './types'

export const useConfiguration = defineStore('configuration', () => {
  //global ready param
  const appIsReady = ref(false)
  const initApp = () => (appIsReady.value = true)

  //app update property
  const appUpdateKey = ref(0)
  const globalLoading = ref(false)
  const showGlobalPreloader = ref(false)
  const loadingTimer = ref<Undefinable<NodeJS.Timeout>>(undefined)

  const updateAppOnCriticalParamsChange = async <T>(callback: Promise<T>) => {
    globalLoading.value = true

    loadingTimer.value = setTimeout(() => {
      showGlobalPreloader.value = true
    }, getMilliseconds.inSeconds(0.5))

    await callback

    clearTimeout(loadingTimer.value)
    appUpdateKey.value++
    globalLoading.value = false
    showGlobalPreloader.value = false
  }

  //app config
  const configuration = shallowRef<Undefinable<ConfigurationDTO>>(undefined)
  const getConfiguration = async (partnerKeyString: string) => {
    try {
      configuration.value = await configurationService.get(partnerKeyString)

      return configuration.value
    } catch (error) {
      partnerKey.handleEmpty()
    }
  }
  //shared
  const themePrefix = computed(() => '')
  const ageIntervals = computed(() => configuration.value?.ageIntervals || userAgeIntervalsDefault)
  const locations = computed(() => {
    if (!configuration.value?.locations) return

    const arr = [...configuration.value.locations]

    arr.sort(({ name: nameA }, { name: nameB }) => {
      return nameA.localeCompare(nameB)
    })

    return arr
  })
  const userAgeIntervalsList = computed<UserAgeInterval[]>(() =>
    ageIntervals.value.map((item) => item.interval)
  )
  const categoriesHandbook = computed(() => configuration.value?.categories)
  const getCategoryFromHandbook = (categoryId: CategoryShort['id']) =>
    categoriesHandbook.value?.find((item) => item.id === categoryId)

  return {
    appIsReady,
    initApp,

    appUpdateKey,
    globalLoading,
    showGlobalPreloader,
    updateAppOnCriticalParamsChange,

    configuration,
    getConfiguration,

    themePrefix,
    ageIntervals,
    locations,
    userAgeIntervalsList,
    categoriesHandbook,
    getCategoryFromHandbook
  }
})
