<template>
  <main v-if="categoryResults" class="relative pt-5">
    <header class="sticky inset-0 z-10 flex items-center bg-primary px-4">
      <GoBack class="mr-3" />
      <h1 class="text-2xl font-bold">{{ categoryResults.name }}</h1>
    </header>
    <SearchResultList :value="showingItems!" />
    <UiEternalLoading
      v-if="!allItemsIsVisible"
      :all-loaded="allItemsIsVisible"
      :handler="loadNextPage"
    />
  </main>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, toRef } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { GoBack } from '@/4_features/Navigation'
import {
  SearchResultList,
  type SearchResultsItem,
  type SearchResultsObject,
  useSavedSearch,
  useSearch
} from '@/5_entities/Search'
import { usePageInfiniteLoading } from '@/6_shared/composables'
import { UiEternalLoading } from '@/6_shared/ui'

const { results, searchCategoryPage } = storeToRefs(useSearch())
const { params } = useRoute()
const router = useRouter()

const categoryResults = computed(
  (): SearchResultsItem | undefined =>
    results.value && results.value.find((item) => item.id === Number(params.id))
)

onBeforeMount(() => {
  if (!categoryResults.value) router.push({ name: 'SearchView' })
})

const PAGE_SIZE_BY_SEARCH_CATEGORY = 20
const { showingItems, allItemsIsVisible, loadNextPage } =
  usePageInfiniteLoading<SearchResultsObject>(
    PAGE_SIZE_BY_SEARCH_CATEGORY,
    searchCategoryPage,
    toRef(categoryResults.value?.repertoire)
  )

const ROUTES_WITH_SAVED_SEARCH = ['EventView', 'VenueView', 'SearchView']
useSavedSearch(ROUTES_WITH_SAVED_SEARCH, () => (searchCategoryPage.value = 1))

onBeforeRouteLeave((_to, from, next) => {
  from.meta = {
    ...from.meta,
    saveSearch: true
  }

  next()
})
</script>
