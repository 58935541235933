<template>
  <div class="flex items-center">
    <p class="font-bold">{{ title }}:</p>

    <div class="ml-2 rounded-md border p-1">
      <UiColorPicker :model-value="value" @update:model-value="changeThemeColor($event, title)">
        <template #label>{{ value }}: </template>
      </UiColorPicker>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ThemeConfigurationChangeColorEmit } from '@/5_entities/Configuration'
import { UiColorPicker } from '@/6_shared/ui'

type PropType = {
  title: string
  value: string
}

type EmitType = {
  (e: 'changeColor', obj: ThemeConfigurationChangeColorEmit): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()

const changeThemeColor = (newColor: string, themeKey: string) => {
  emit('changeColor', { color: newColor, themeKey })
}
</script>

<style>
.vc-color-wrap {
  margin-right: 0 !important;
}
</style>
