<template>
  <main class="pb-10">
    <VenuePoster :value="venue" :loading="!initialized" />
    <VenueBaseInfo :value="venue" :loading="!initialized" class="relative -mt-5" />
    <VenueRepertoirePreview :value="venue" :loading="!initialized" class="mb-4" />
    <VenueAddressInfo :value="venue" :loading="!initialized" />
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import {
  VenueAddressInfo,
  VenueBaseInfo,
  VenuePoster,
  VenueRepertoirePreview
} from '@/3_widgets/Venue'
import { useVenue } from '@/5_entities/Venue'

const { getVenue } = useVenue()
const { venue, initialized } = storeToRefs(useVenue())

onBeforeMount(() => {
  const { params } = useRoute()

  if (!venue.value || Number(params.id as string) !== venue.value.id) getVenue(Number(params.id))
})
</script>
