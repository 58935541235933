<template>
  <UiContainer tag="section">
    <template v-if="value && !loading">
      <RouterLink
        v-if="!hideHeader"
        custom
        :to="{ name: 'CategoryItemView', params: { id: value.id }, query: filters }"
        v-slot="{ href, navigate }"
      >
        <header class="mb-3">
          <a :href="href" class="flex items-center justify-between" @click.prevent="navigate()">
            <UiTitle severity="h1" tag="h2" class="!font-bold">
              {{ value.name }}
            </UiTitle>
            <UiIcon name="arrow-right" class="size-5 text-icon-primary" />
          </a>
        </header>
      </RouterLink>
      <ul class="flex flex-col gap-8">
        <RouterLink
          v-for="eventValue in visibleEvents"
          :key="eventValue.id"
          :to="{ name: 'EventView', params: { id: eventValue.id } }"
          custom
          v-slot="{ href, navigate }"
        >
          <li>
            <a :href="href" @click.prevent="navigate()">
              <EventCard :value="eventValue" size="big" />
            </a>
          </li>
        </RouterLink>
      </ul>
    </template>
    <template v-else>
      <CategoryResultsSkeleton />
    </template>
  </UiContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { LocationQueryRaw } from 'vue-router'
import type { Category } from '@/5_entities/Category'
import { EventCard } from '@/5_entities/Event'
import { UiContainer, UiTitle, UiIcon } from '@/6_shared/ui'
import CategoryResultsSkeleton from './CategoryResultsSkeleton.vue'

type PropType = {
  value?: Category
  loading?: boolean
  maxItems?: number
  filters?: LocationQueryRaw
  hideHeader?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined,
  maxItems: undefined,
  filters: undefined,
  infiniteLoading: false,
  hideHeader: false
})
const visibleEvents = computed(() => {
  if (!props.value) return []
  if (!props.maxItems) return props.value.repertoire

  return props.value.repertoire.filter((_, index) => index < props.maxItems!)
})
</script>
