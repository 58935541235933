<template>
  <div>
    <VenueRepertoireList v-if="showingItems" :value="showingItems" />
    <UiEternalLoading :handler="loadNextPage" :all-loaded="allItemsIsVisible" />
  </div>
</template>

<script setup lang="ts">
import { ref, toRef } from 'vue'
import type { Event } from '@/5_entities/Event'
import { type Venue, VenueRepertoireList } from '@/5_entities/Venue'
import { usePageInfiniteLoading } from '@/6_shared/composables'
import { UiEternalLoading } from '@/6_shared/ui'

type PropType = {
  value: Venue['repertoire']
}

const props = defineProps<PropType>()

const PAGE_SIZE = 10
const page = ref(1)
const { showingItems, loadNextPage, allItemsIsVisible } = usePageInfiniteLoading<Event>(
  PAGE_SIZE,
  page,
  toRef(props.value)
)
</script>
