<template>
  <UiContainer tag="section">
    <header class="sr-only">
      <UiTitle severity="h2"> Расписание </UiTitle>
    </header>
    <template v-if="!loading && value">
      <LookEventSeancesPreview
        :value="value.seances"
        :event-id="value.id"
        :value-count="value.eventSeancesCount"
        @open-timetable="emit('openTimetable', $event)"
      />
    </template>
    <template v-else>
      <EventTimetablePreviewSkeleton />
    </template>
  </UiContainer>
</template>

<script lang="ts" setup>
import { LookEventSeancesPreview } from '@/4_features/Event'
import type { Event } from '@/5_entities/Event'
import { UiTitle, UiContainer } from '@/6_shared/ui'
import EventTimetablePreviewSkeleton from './EventTimetablePreviewSkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}
type EmitType = {
  (e: 'openTimetable', eventId: Event['id']): void
}
withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false
})
const emit = defineEmits<EmitType>()
</script>
