<template>
  <div />
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import {
  useConfiguration,
  themeConstructor,
  partnerKey,
  getParsedInitialParamsFromQuery
} from '@/5_entities/Configuration'
import type { ConfigurationDTO } from '@/5_entities/Configuration'
import { DEFAULT_DEV_PARTNER_ID, useRuntimeConfig } from '@/6_shared/config'
import { SessionStorageNames, useSessionStorage } from '@/6_shared/lib'

type EmitType = {
  (e: 'configurationInitialized'): void
}

const emit = defineEmits<EmitType>()

const { getConfiguration } = useConfiguration()

const getConfigFromUrl = () => {
  const query = getParsedInitialParamsFromQuery()
  const getPartnerKey = () => {
    const { VITE_APP_IS_DEV } = useRuntimeConfig()
    const defaultKey = DEFAULT_DEV_PARTNER_ID

    const fromQuery = query?.partnerKey as string
    const keyFromLocalStorage = partnerKey.get()
    const fromDevStand = Number(VITE_APP_IS_DEV) === 1 ? defaultKey : ''
    const fromDevMode = !import.meta.env.PROD && defaultKey

    return fromQuery || keyFromLocalStorage || fromDevStand || fromDevMode
  }
  const getThemeConfigurator = () => {
    return !!query?.enableThemeConfigurator
  }

  return {
    partnerKey: getPartnerKey(),
    enableThemeConfigurator: getThemeConfigurator()
  }
}

const setTheme = async (config: ConfigurationDTO['theme']) => {
  //Темизация
  // 1. получаем конфигурацию приложения
  // 2. если тема пришла, устанавливаем её
  // themeConstructor.setTheme(theme)
  // 3. если нет, ставим дефолтную в
  // зависимости от предустановок телефона
  // пока ставим всегда дефолт
  if (config) {
    themeConstructor.setTheme(config)
  } else {
    themeConstructor.setDefaultTheme()
  }
}

const setEnableThemeConfiguratorInSessionStorage = (toggler: boolean) => {
  toggler &&
    useSessionStorage().set(SessionStorageNames.enableThemeConfigurator, toggler.toString())
}

onBeforeMount(async () => {
  const config = getConfigFromUrl()

  if (!config.partnerKey) {
    return partnerKey.handleEmpty()
  }
  setEnableThemeConfiguratorInSessionStorage(config.enableThemeConfigurator)

  partnerKey.set(config.partnerKey)
  const configuration = await getConfiguration(config.partnerKey)
  await setTheme(configuration?.theme)

  emit('configurationInitialized')
})
</script>
