<template>
  <UiContainer
    tag="section"
    rounded="top"
    class="relative h-fit bg-primary py-3 lg:bg-transparent lg:!px-0 lg:py-4 lg:pb-0"
  >
    <UiGradientBackground class="absolute inset-0 rounded-t-4xl lg:hidden" />
    <template v-if="!loading && value">
      <UiTitle severity="h1" tag="h1" class="relative mb-0 text-xl lg:mb-5 lg:text-3xl">
        {{ value.title }}
        <span class="inline text-base font-normal text-text-fivefold">{{ value.ageCategory }}</span>
      </UiTitle>
      <SeanceCard
        v-if="isLgAndUp && value.seances && value.seances.length"
        :value="[value.seances[0]]"
        :event-id="value.id"
        :loading="loading"
        type="nearest"
        class="left-0 top-full -mb-7 lg:absolute lg:mb-0"
      />
      <p v-if="!value.seances.length" class="relative text-sm">все билеты выкуплены</p>
    </template>
    <template v-else>
      <EventBaseInfoSkeleton class="-mb-7 lg:mb-0" />
    </template>
  </UiContainer>
</template>

<script lang="ts" setup>
import { type Event } from '@/5_entities/Event'
import { SeanceCard } from '@/5_entities/Seance'
import { useBreakpoint } from '@/6_shared/lib'
import { UiGradientBackground, UiTitle } from '@/6_shared/ui'
import { UiContainer } from '@/6_shared/ui'
import EventBaseInfoSkeleton from './EventBaseInfoSkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}

withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})

const {
  lg: [isLgAndUp]
} = useBreakpoint()
</script>
