<template>
  <form @submit.prevent>
    <SeanceToggler
      v-for="[filterDay] in value"
      :key="filterDay.id"
      :value="filterDay"
      :model-value="modelValue"
      :type="type"
      :class="elementClasses"
      @update:model-value="emit('update:modelValue', $event)"
    />
    <slot name="more-button" />
  </form>
</template>

<script lang="ts" setup>
import { type Seance, SeanceToggler } from '@/5_entities/Seance'

type PropType = {
  value: Seance[][]
  modelValue?: Seance['id']
  type?: 'checkbox' | 'radio'
  elementClasses?: string
}
type EmitType = {
  (e: 'update:modelValue', value: Seance['id'] | undefined): void
}

withDefaults(defineProps<PropType>(), {
  modelValue: undefined,
  type: 'checkbox',
  elementClasses: ''
})
const emit = defineEmits<EmitType>()
</script>
