<template>
  <UiContainer v-bind="{ ...$attrs }" tag="section" class="flex gap-3">
    <UiButton
      size="small"
      severity="tertiary"
      class="flex items-center gap-x-2.5 rounded-lg"
      @click="showCalendar = true"
    >
      <UiIcon name="calendar" class="size-4 text-icon-secondary" />
      Календарь
    </UiButton>
    <UiDateHelpers button-classes="rounded-lg" @update:model-value="emit('selectDate', $event)" />
  </UiContainer>

  <UiDialog
    v-model="showCalendar"
    position="bottom"
    close-position="left"
    close-classes="-top-1 left-2"
    class="h-[97vh] rounded-t-3xl bg-primary pt-4"
    swipe-close
  >
    <template #header>
      <div class="w-full bg-primary text-center">
        <UiTitle severity="h2" class="mx-auto">Выбрать дату</UiTitle>
      </div>
    </template>
    <UiDatePicker
      range
      need-confirm
      @update:model-value="emit('selectDate', $event)"
      @close="showCalendar = false"
    />
  </UiDialog>
</template>

<script setup lang="ts">
import type { Undefinable } from 'ts-helpers'
import { ref } from 'vue'
import {
  UiButton,
  UiContainer,
  UiDateHelpers,
  UiDatePicker,
  UiDialog,
  UiIcon,
  UiTitle
} from '@/6_shared/ui'

type EmitType = {
  (e: 'selectDate', date: Undefinable<Date | Date[]>): void
}
const emit = defineEmits<EmitType>()

const showCalendar = ref(false)

defineOptions({
  inheritAttrs: false
})
</script>
