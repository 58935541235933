<template>
  <nav>
    <ul class="flex gap-2">
      <li v-for="(helper, helperName) in helpers" v-show="!helper.disabled" :key="helperName">
        <UiButton
          :severity="helper.active ? 'primary' : 'tertiary'"
          size="small"
          :disabled="helper.disabled"
          :class="buttonClasses"
          @click="helper.handler()"
        >
          {{ helperName }}
        </UiButton>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import {
  addDays,
  isSameDay,
  isSaturday,
  isSunday,
  isToday,
  isTomorrow,
  nextSaturday,
  nextSunday
} from 'date-fns'
import { computed } from 'vue'
import UiButton from './UiButton.vue'

type PropType = {
  modelValue?: Date | Date[]
  availableDates?: Date[]
  buttonClasses?: string
}
type EmitType = {
  (e: 'update:modelValue', date: Date | Date[] | undefined): void
}

const props = withDefaults(defineProps<PropType>(), {
  modelValue: undefined,
  availableDates: undefined,
  buttonClasses: ''
})
const emit = defineEmits<EmitType>()
const today = new Date()

const checkDisable = (date: Date) => {
  if (!props.availableDates) return false

  for (const availableDate of props.availableDates) {
    if (isSameDay(availableDate, date)) return false
  }

  return true
}

const selectToday = () => {
  if (isTodaySelected.value) return emit('update:modelValue', undefined)
  emit('update:modelValue', today)
}
const isTodaySelected = computed(
  () => !!props.modelValue && !Array.isArray(props.modelValue) && isToday(props.modelValue as Date)
)
const selectTomorrow = () => {
  if (isTomorrowSelected.value) return emit('update:modelValue', undefined)
  emit('update:modelValue', addDays(today, 1))
}
const isTomorrowSelected = computed(
  () =>
    !!props.modelValue && !Array.isArray(props.modelValue) && isTomorrow(props.modelValue as Date)
)
const selectWeekend = () => {
  if (isWeekendSelected.value) return emit('update:modelValue', undefined)
  const canSelectRange = isSaturday(today) || (!isSaturday(today) && !isSunday(today))
  if (canSelectRange) {
    emit('update:modelValue', [isSaturday(today) ? today : nextSaturday(today), nextSunday(today)])
  } else {
    emit('update:modelValue', today)
  }
}
const isWeekendSelected = computed(() => {
  const baseCondition = props.modelValue && Array.isArray(props.modelValue)

  if (!baseCondition) return false
  const firstDate = (props.modelValue as Date[])[0] as Date
  const secondDate = (props.modelValue as Date[])[1] as Date

  const isCurrentSaturday =
    isSaturday(firstDate) && isSameDay(firstDate, isSaturday(today) ? today : nextSaturday(today))
  const isCurrentSunday = isSameDay(secondDate, nextSunday(today))

  return isCurrentSaturday && isCurrentSunday
})

const helpers = computed(() => ({
  Сегодня: {
    active: isTodaySelected.value,
    handler: selectToday,
    disabled: checkDisable(today)
  },
  Завтра: {
    active: isTomorrowSelected.value,
    handler: selectTomorrow,
    disabled: checkDisable(addDays(today, 1))
  },
  Выходные: {
    active: isWeekendSelected.value,
    handler: selectWeekend,
    disabled: checkDisable(nextSunday(today)) || checkDisable(nextSunday(today))
  }
}))
</script>
