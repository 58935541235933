<template>
  <li @click="copyVenueAddress">
    <Component
      :is="isLink ? 'RouterLink' : 'button'"
      :type="!isLink ? 'button' : ''"
      :to="{ name: 'VenueView', params: { id: value.id } }"
      class="flex size-full rounded-xl bg-secondary p-3 text-left"
    >
      <div class="mr-2 w-full">
        <h3 class="mb-0.5 break-words text-sm">
          {{ value.title }}
        </h3>
        <p v-if="value.address" class="text-xs text-text-secondary">
          {{ value.address.normalized }}
        </p>
      </div>
      <ThemeIcon
        :name="isLink ? 'arrow-right' : 'square-2-stack'"
        :class="!isLink ? 'text-text-quaternary' : 'text-text-primary'"
        class="max-h-5 min-h-5 min-w-5 max-w-5"
      />
    </Component>
  </li>
</template>

<script lang="ts" setup>
import copyToClipboard from 'copy-to-clipboard'
import { computed } from 'vue'
import { toast } from 'vue3-toastify'
import { ThemeIcon } from '@/5_entities/Configuration'
import type { Venue } from '../../model'

type PropType = {
  value: Venue
  type?: 'link' | 'info'
}

const props = withDefaults(defineProps<PropType>(), {
  type: 'link'
})

const isLink = computed(() => props.type === 'link')

const copyVenueAddress = () => {
  if (isLink.value) return

  props.value.address && copyToClipboard(props.value.address.normalized)
  toast.success('Адрес скопирован')
}
</script>
