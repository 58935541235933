import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import type { CompilationDTO, CompilationFilters } from '../model'
import { compilationErrorsHandbook } from './error'

class CompilationService extends BaseService {
  private readonly _endpoint: string = 'compilations'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public getPack(packName: string, query?: CompilationFilters) {
    return this._http.get<CompilationDTO[]>([this._endpoint, packName].join('/'), {
      needCancelOnRouteChange: true,
      query,
      queryAsString: true
    })
  }

  public loadItems(slug: string, query?: CompilationFilters) {
    return this._http.get<CompilationDTO>([this._endpoint, slug, 'items'].join('/'), {
      generalUrl: 'compilation:id.items',
      needCancelOnRouteChange: true,
      query,
      queryAsString: true
    })
  }

  public get(slug: string, query?: CompilationFilters) {
    return this._http.get<CompilationDTO>([this._endpoint, slug, 'items'].join('/'), {
      generalUrl: 'company:id',
      needCancelOnRouteChange: true,
      query,
      queryAsString: true
    })
  }
}

const compilationService = new CompilationService(HttpService, compilationErrorsHandbook)

export { compilationService }
