<template>
  <UiButton
    severity="tertiary"
    tag="label"
    icon
    class="cursor-pointer border border-quaternary"
    title="Загрузить тему"
  >
    <input
      type="file"
      accept="application/json"
      class="absolute -z-10 size-0 opacity-0"
      @change="uploadTheme"
    />
    <UiIcon name="arrow-up-tray" class="size-5" />
  </UiButton>
</template>

<script setup lang="ts">
import { UiButton, UiIcon } from '@/6_shared/ui'

type EmitType = {
  (e: 'upload', file: any): void
}
const emit = defineEmits<EmitType>()

const uploadTheme = (e: Event) => {
  const file: File | null = (e.target as HTMLInputElement).files
    ? (e.target as HTMLInputElement).files![(e.target as HTMLInputElement).files!.length - 1]
    : null

  if (!file || file.type !== 'application/json') return

  file.text().then((content) => {
    emit('upload', JSON.parse(content))
  })
}
</script>
