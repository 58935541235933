<template>
  <UiButton
    tag="header"
    text
    class="mb-2 flex w-full cursor-pointer items-center gap-2 rounded-none border-b border-primary"
    @click="emit('update:open', !open)"
  >
    <UiIcon
      name="chevron-down"
      class="size-5 transition-transform"
      :class="{ 'rotate-180': open }"
    />
    <UiTitle v-if="title" severity="h3">
      {{ title }}
    </UiTitle>
  </UiButton>
</template>

<script setup lang="ts">
import { UiButton, UiIcon, UiTitle } from '@/6_shared/ui'

type PropType = {
  title?: string
  open: boolean
}
type EmitType = {
  (e: 'update:open', value: boolean): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()
</script>
