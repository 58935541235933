<template>
  <label>
    <input
      :type="type"
      :value="value.id"
      :checked="isActive"
      class="peer absolute size-0 shrink-0 opacity-0"
      @change="changeValue"
    />
    <span
      :class="[{ 'gradient-card': isActive }, $attrs.class]"
      class="flex w-full cursor-pointer select-none flex-col rounded-lg border border-card-primary bg-primary px-2 py-1 transition-all peer-hover:border-card-primary-hover peer-hover:shadow-card_primary_hover peer-focus:shadow-card_primary_hover"
    >
      <UiDate :value="value.beginsAt" template="d" class="font-semibold" />
      <UiDate :value="value.beginsAt" template="MMMM" class="text-xs font-semibold" />
      <span class="flex items-center gap-1">
        <UiDate :value="value.beginsAt" template="yyyy" class="text-xs text-text-fivefold" />
        <UiDate
          :value="value.beginsAt"
          template="EEEEEE"
          :class="{ 'text-text-primary': isWeekend(value.beginsAt) }"
          class="font-base text-xs text-text-fivefold"
        />
      </span>
    </span>
  </label>
</template>

<script lang="ts" setup>
import { isWeekend } from 'date-fns'
import { computed } from 'vue'
import { UiDate } from '@/6_shared/ui'
import type { Seance } from '../../model'

type PropType = {
  modelValue?: Seance['id']
  value: Seance
  type?: 'checkbox' | 'radio'
}
type EmitType = {
  (e: 'update:modelValue', value: Seance['id'] | undefined): void
}

const props = withDefaults(defineProps<PropType>(), {
  modelValue: undefined,
  type: 'checkbox'
})
const emit = defineEmits<EmitType>()

const isActive = computed(() => props.modelValue === props.value.id)

const changeValue = () => {
  const emitValue =
    props.type === 'checkbox' ? (isActive.value ? undefined : props.value.id) : props.value.id

  emit('update:modelValue', emitValue)
}

defineOptions({
  inheritAttrs: false
})
</script>
