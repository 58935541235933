<template>
  <div class="h-dvh min-h-[400px] w-screen bg-primary !p-0">
    <div v-if="!unavailableError && !notFoundError" class="relative size-full">
      <SeanceWidgetLoader v-if="loading" />
      <iframe
        v-else-if="showWidget"
        width="100%"
        height="100%"
        :src="widgetUrl"
        title="Виджет для покупки билета"
      />
    </div>
    <SeanceWidgetErrors
      v-else
      :not-found-error="notFoundError"
      :unavailable-error="unavailableError"
      @reload-widget="openWidget(seanceId)"
      @delete-seance="onDeleteSeance"
    />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue'
import { appMessenger } from '@/5_entities/Configuration'
import { type Seance, SeanceWidgetLoader } from '@/5_entities/Seance'
import { useOpenSeanceWidget } from '../model'
import SeanceWidgetErrors from './SeanceWidgetErrors.vue'

type PropType = {
  seanceId: Seance['id']
}
type EmitType = {
  (e: 'deleteSeance', id: Seance['id']): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()

const { showWidget, loading, widgetUrl, openWidget, unavailableError, notFoundError } =
  useOpenSeanceWidget()

const onDeleteSeance = () => {
  showWidget.value = false
  emit('deleteSeance', props.seanceId)
}

onMounted(() => openWidget(props.seanceId))
onBeforeUnmount(() => appMessenger.widget.close())
</script>
