import type { SeanceDTO } from '@/5_entities/Seance'
import { type ErrorsHandbook, HttpService, BaseService } from '@/6_shared/api'
import type { SearchResult } from '@/6_shared/model'
import { type EventDTO } from '../model'
import { eventErrorsHandbook } from './error'

export * from './error'

class EventService extends BaseService {
  private readonly _endpoint: string = 'events'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public get(id: number) {
    return this._http.get<EventDTO>([this._endpoint, id].join('/'), {
      generalUrl: 'events:id',
      needCancelOnRouteChange: true,
      appMessageErrors: [404]
    })
  }

  public loadSeances(eventId: number, pageNumber: number) {
    return this._http.get<SearchResult<'seance', SeanceDTO>>(
      [this._endpoint, eventId, 'seances'].join('/'),
      {
        generalUrl: 'events:id.seances',
        needCancelOnRouteChange: true,
        query: {
          pageNumber,
          pageSize: 10
        }
      }
    )
  }
}

const eventService = new EventService(HttpService, eventErrorsHandbook)

export { eventService }
