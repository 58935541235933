import { onBeforeRouteLeave } from 'vue-router'

export const useSavedSearch = (routesWithSavedSearch: string[], resetHandler: () => void) => {
  onBeforeRouteLeave((to, _from, next) => {
    if (to?.name && !routesWithSavedSearch.includes(String(to.name))) {
      resetHandler()
    } else {
      to.meta = {
        ...to.meta,
        saveSearch: 'true'
      }
    }

    next()
  })
}
