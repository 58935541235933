<template>
  <div class="relative h-screen overflow-y-scroll bg-primary" :class="{ 'w-1/2': !isMobile }">
    <div class="sticky left-0 top-0 z-10 flex w-full justify-between bg-primary">
      <UiTitle>Настройка темы:</UiTitle>
      <UiButton v-if="isMobile" severity="tertiary" size="small">
        <UiIcon name="x-mark" class="size-5" @click="emit('closeModal')" />
      </UiButton>
    </div>
    <ConfigurationThemeConstructorEditor
      :value="value"
      @change-prop="emit('propChange', $event)"
      @highlight-prop="emit('highlightProp', $event)"
      @off-highlight="emit('offHighlight')"
    />
    <div class="sticky bottom-4 left-full flex justify-end gap-2 pr-4">
      <DownloadConstructedTheme :value="value" class="size-8" />
      <UploadTheme class="size-8" @upload="emit('themeUpload', $event)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DownloadConstructedTheme, UploadTheme } from '@/4_features/Configuration'
import type { Theme, ThemeConfigurationChangePropEmit } from '@/5_entities/Configuration'
import { UiButton, UiIcon, UiTitle } from '@/6_shared/ui'
import ConfigurationThemeConstructorEditor from './ConfigurationThemeConstructorEditor.vue'

type PropType = {
  value: Theme
  isMobile: boolean
}
type EmitType = {
  (e: 'closeModal'): void
  (e: 'propChange', obj: ThemeConfigurationChangePropEmit): void
  (e: 'themeUpload', uploadedTheme: Theme): void
  (e: 'highlightProp', props: string): void
  (e: 'highlightProp', props: string): void
  (e: 'offHighlight'): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()
</script>
