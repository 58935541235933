<template>
  <div v-if="value" class="flex h-fit flex-wrap gap-3 lg:block">
    <UiButton
      v-if="value.categoryId && getCategoryFromHandbook(value.categoryId)"
      tag="RouterLink"
      severity="secondary"
      :text="isLgAndUp"
      :link="isLgAndUp"
      :to="{ name: 'CategoryItemView', params: { id: value.categoryId } }"
      class="flex items-center gap-1 rounded-lg !p-2 !text-sm"
    >
      {{ getCategoryFromHandbook(value.categoryId)!.name }}
      <template v-if="isLgAndDown">
        <UiIcon name="arrow-right" class="size-3" />
      </template>
    </UiButton>
    <UiButton
      v-if="value.isPushkin"
      :text="isLgAndUp"
      class="flex items-center gap-1 rounded-lg !p-2 !text-sm"
      :link="isLgAndUp"
      severity="secondary"
    >
      Пушкинская карта
      <template v-if="isLgAndDown">
        <UiIcon name="arrow-right" class="size-3" />
      </template>
    </UiButton>
  </div>
</template>

<script setup lang="ts">
import { useConfiguration } from '@/5_entities/Configuration'
import type { Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiIcon } from '@/6_shared/ui'

type PropType = {
  value?: Event
}
withDefaults(defineProps<PropType>(), {
  value: undefined
})

const { getCategoryFromHandbook } = useConfiguration()
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
</script>
