<template>
  <section ref="container" class="!relative bg-secondary !p-0 lg:bg-transparent">
    <template v-if="!loading && value">
      <div class="flex size-full min-h-max flex-col lg:flex-row">
        <div
          v-if="seancesByDay && seancesByDay.length > 1"
          class="sticky top-0 z-10 h-fit max-h-[calc(100dvh_-_16px)] w-full overflow-hidden bg-secondary lg:max-h-[calc(100vh_-_64px)] lg:max-w-[280px] lg:bg-transparent lg:p-4"
        >
          <template v-if="!showCalendar && seancesByDayLessEightDays">
            <header class="px-6 pt-5 lg:px-2.5 lg:pt-0">
              <UiTitle severity="h5"> Расписание </UiTitle>
              <p class="hidden text-xs text-text-secondary lg:block">выберите дату</p>
            </header>
            <FilterSeancesPreviewByDay
              v-model="activeSeance as Seance['id'] | undefined"
              :value="seancesByDayLessEightDays"
              element-classes="min-w-[104px] lg:min-w-1 shadow-card_primary lg:shadow-none"
              class="scrollbar-hidden mt-7 flex gap-2 overflow-x-scroll px-4 pb-4 lg:mt-2 lg:grid lg:grid-cols-2 lg:px-0 lg:pb-0"
            />
          </template>
          <template v-else>
            <UiDatePicker
              v-if="isLgAndUp"
              class="scrollbar-hidden max-h-[calc(100vh_-_80px)] !p-0"
              range
              show-reset
              header-classes="mb-3 flex items-center justify-between"
              :available-dates="availableForCalendarDates"
              @update:model-value="onCalendarChange"
            >
              <template #header>
                <UiTitle severity="h5">календарь</UiTitle>
              </template>
            </UiDatePicker>

            <template v-else>
              <header class="flex w-full items-center justify-between px-6 py-3 pr-16">
                <UiTitle severity="h5"> Расписание </UiTitle>
                <button
                  type="button"
                  class="flex items-center rounded-[100px] bg-primary p-2.5 text-xs font-semibold"
                  @click="mobileCalendarIsOpen = true"
                >
                  <UiIcon name="calendar" class="mr-1 size-5" />
                  выберите когда
                </button>
              </header>

              <UiDialog
                v-model="mobileCalendarIsOpen"
                swipe-close
                save-scroll-lock
                position="bottom"
              >
                <header>
                  <UiButton
                    icon
                    class="!absolute right-4 top-7 z-20 size-7 bg-primary"
                    @click="mobileCalendarIsOpen = false"
                  >
                    <UiIcon name="x-mark" class="size-5 text-text-main lg:text-light" />
                  </UiButton>
                </header>
                <UiDatePicker
                  class="scrollbar-hidden z-10 !h-[calc(100dvh_-_16px)] rounded-t-3xl !p-0 !px-5"
                  :model-value="mobileCalendarDates"
                  range
                  :available-dates="availableForCalendarDates"
                  need-confirm
                  show-reset
                  header-classes="mb-3 flex px-0 pt-7 "
                  @update:model-value="onCalendarChange"
                >
                  <template #header>
                    <div>
                      <UiTitle severity="h5">календарь</UiTitle>
                    </div>
                  </template>
                  <template #confirm-text>Показать сеансы</template>
                </UiDatePicker>
              </UiDialog>
            </template>
          </template>
        </div>
        <LookEventSeances
          v-if="filteredSeances"
          :value="filteredSeances"
          :class="{
            'lg:shadow-fivefold': seancesByDay && seancesByDay.length > 1,
            'gradient-card': isLgAndUp
          }"
          class="min-h-max px-4 pb-4.5 lg:size-full lg:py-4.5"
        />
      </div>
    </template>
    <template v-else>
      <EventTimetablePreviewSkeleton :count="10" />
    </template>
  </section>
</template>

<script lang="ts" setup>
import { isAfter, isSameDay, isBefore } from 'date-fns'
import type { Undefinable } from 'ts-helpers'
import { computed, defineAsyncComponent, ref, shallowRef, watch } from 'vue'
import type { Event } from '@/5_entities/Event'
import { getSeancesByDay, type Seance } from '@/5_entities/Seance'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiDatePicker, UiDialog, UiIcon, UiTitle } from '@/6_shared/ui'
import EventTimetablePreviewSkeleton from './EventTimetablePreviewSkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})

const FilterSeancesPreviewByDay = defineAsyncComponent({
  loader: async () => {
    const { FilterSeancesPreviewByDay } = await import('@/4_features/Event')

    return FilterSeancesPreviewByDay
  }
})
const LookEventSeances = defineAsyncComponent({
  loader: async () => {
    const { LookEventSeances } = await import('@/4_features/Event')

    return LookEventSeances
  }
})

const {
  lg: [isLgAndUp]
} = useBreakpoint()
const MAX_VISIBLE_DAYS = 8
const activeSeance = shallowRef<Undefinable<Seance['id'] | Seance['id'][]>>(undefined)
const mobileCalendarIsOpen = ref(false)

/**
 * даты отображения в мобильном календаре
 */
const mobileCalendarDates = computed((): Undefinable<Date | Date[]> => {
  if (isLgAndUp.value || !activeSeance.value || !props.value) return

  if (Array.isArray(activeSeance.value))
    return [
      new Date(props.value.findSeanceById(activeSeance.value[0])!.beginsAt),
      new Date(props.value.findSeanceById(activeSeance.value[1])!.beginsAt)
    ]

  return new Date(props.value.findSeanceById(activeSeance.value)!.beginsAt)
})
/**
 * все сеансы, сгруппированные по дням
 */
const seancesByDay = computed(() => props.value && getSeancesByDay(props.value.seances))
/**
 * отображать ли календарь
 */
const showCalendar = computed(
  () => seancesByDay.value && seancesByDay.value.length > MAX_VISIBLE_DAYS
)
/**
 * сенасы по дня, но < 8 дней
 */
const seancesByDayLessEightDays = computed(
  () =>
    !showCalendar.value &&
    !!seancesByDay.value &&
    seancesByDay.value.filter((_item, index) => index < MAX_VISIBLE_DAYS)
)
/**
 * отфильтрованные по выбранному дню сеансы
 */
const filteredSeances = computed(() => {
  const findSeance = (seanceId: Seance['id']) => props.value && props.value.findSeanceById(seanceId)
  const filterSeancesFoLessEightDays = () =>
    activeSeance.value
      ? [seancesByDay.value!.find(([seance]) => seance.id === activeSeance.value)!]
      : seancesByDayLessEightDays.value
  const filterSeancesForCalendar = () => {
    const findSeancesForRange = (beginsAt: Seance['beginsAt']) => {
      const [firsSeanceId, secondSeanceId] = activeSeance.value as Seance['id'][]
      const firstSeance = findSeance(firsSeanceId)
      const secondSeance = findSeance(secondSeanceId)

      return (
        (isSameDay(firstSeance!.beginsAt, beginsAt) || isAfter(beginsAt, firstSeance!.beginsAt)) &&
        (isSameDay(secondSeance!.beginsAt, beginsAt) || isBefore(beginsAt, secondSeance!.beginsAt))
      )
    }

    return activeSeance.value
      ? seancesByDay.value!.filter(([seance]) => {
          const { beginsAt } = seance

          if (Array.isArray(activeSeance.value)) return findSeancesForRange(beginsAt)

          return isSameDay(beginsAt, findSeance(activeSeance.value!)!.beginsAt)
        })
      : seancesByDay.value
  }

  if (!props.value) return

  if (!showCalendar.value) {
    return filterSeancesFoLessEightDays()
  }

  return filterSeancesForCalendar()
})
/**
 * выбор даты в календаре
 * @param value
 */
const onCalendarChange = (value: Undefinable<Date | Date[]>) => {
  mobileCalendarIsOpen.value = false
  const findSeanceId = (date: Date) =>
    props.value && props.value.seances.find((item) => isSameDay(date, item.beginsAt))?.id

  if (!props.value) return
  if (!value) return (activeSeance.value = undefined)

  if (Array.isArray(value)) {
    const [start, end] = value
    const firstSeance = findSeanceId(start)
    const lastSeance = findSeanceId(end)

    activeSeance.value =
      firstSeance && lastSeance ? [firstSeance, lastSeance] : firstSeance || lastSeance

    return
  }

  activeSeance.value = findSeanceId(value)
}
/**
 * только активные даты для календаря
 */
const availableForCalendarDates = computed(() => {
  if (!showCalendar.value || !seancesByDay.value) return

  return seancesByDay.value.map(([seance]) => new Date(seance.beginsAt))
})

/**
 * скроллить окно наверх при выборе даты
 */
const container = ref<HTMLElement | null>(null)
const scrollContainerToTop = () => {
  if (!container.value) return

  container.value.scrollTop = 0
}
watch(() => activeSeance.value, scrollContainerToTop)
</script>
