<template>
  <UiButton
    :to="{ name: 'EventViewTimetable', params: { id: eventId } }"
    :text="isLgAndUp || type === 'edge'"
    :link="isLgAndUp"
    class="block w-full text-left"
    :class="{
      'flex  items-center rounded-lg border border-card-primary !bg-primary !p-3 text-sm font-semibold text-text-main shadow-card_primary_hover':
        isLgAndDown && type === 'info',
      '!px-2': type === 'info' && !isManyDaysWithOneSeance,
      'text-left lg:text-center': type === 'edge'
    }"
    @click="emit('open', eventId)"
  >
    <template v-if="type === 'info'">
      <template v-if="isLgAndUp"> Хочу посмотреть <br />всё расписание </template>
      <template v-else>
        <template v-if="isManyDaysWithOneSeance"> Посмотреть всё расписание </template>
        <template v-else> ещё </template>
        <UiIcon
          name="chevron-down"
          class="ml-auto size-3 min-w-3 -rotate-90 text-icon-quaternary"
        />
      </template>
    </template>
    <template v-if="type === 'edge' && edgeSeances">
      <p class="inline text-center text-sm">
        ещё в <UiDate :value="edgeSeances[0].beginsAt" template="HH:mm" />
        <template v-if="edgeSeances[1]">
          .. <UiDate :value="edgeSeances[1].beginsAt" template="HH:mm" />
        </template>
        <span class="lg:hidden">
          &nbsp;<span class="font-medium">от</span> {{ toRub(edgeSeances[0].priceRange.min) }}
        </span>
      </p>
    </template>
  </UiButton>
</template>

<script setup lang="ts">
import type { Event } from '@/5_entities/Event'
import { toRub, useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiDate, UiIcon } from '@/6_shared/ui'
import type { Seance } from '../../model'

type PropType = {
  eventId: Event['id']
  isManyDaysWithOneSeance?: boolean
  type?: 'info' | 'edge'
  edgeSeances?: Seance[]
}
type EmitType = {
  (e: 'open', eventId: Event['id']): void
}

withDefaults(defineProps<PropType>(), {
  isManyDaysWithOneSeance: false,
  type: 'info',
  edgeSeances: undefined
})
const emit = defineEmits<EmitType>()
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
</script>
