import { FetchError } from 'ofetch'
import type { ErrorCodes, ErrorsHandbook } from './model'

export class UnknownError extends Error {
  public status: number | undefined

  constructor(e: Error, statusCode: number | undefined) {
    super('Неизвестная ошибка: ' + e.message)
    this.name = e.name
    this.stack = e.stack
    this.status = statusCode
  }
}

export class AbortError extends Error {
  constructor(e: Error) {
    super('AbortError')
    this.name = 'AbortError'
    this.stack = e.stack
  }
}

export class UnauthorizedError extends Error {
  public refreshCallback: () => Promise<any>

  constructor(e: Error, refreshCallback: () => Promise<any>) {
    super('UnauthorizedError')
    this.name = 'UnauthorizedError'
    this.stack = e.stack
    this.refreshCallback = refreshCallback
  }
}

export class ErrorInterpreter {
  private readonly _handbook: ErrorsHandbook

  constructor(errorsHandbook: ErrorsHandbook) {
    this._handbook = errorsHandbook
  }

  private _isFetchError = (e: any): e is FetchError<any> => e instanceof FetchError

  private _getErrorFormHandbook(error: FetchError) {
    if (this._isAbortError(error)) return new AbortError(error)

    const { statusCode, response } = error

    if (!statusCode || !response?._data) return new UnknownError(error, statusCode)

    const statusCodeHandbook = this._handbook.get(statusCode as ErrorCodes)

    if (!statusCodeHandbook) return new UnknownError(error, statusCode)

    const responseErrorCode = response?._data?.code

    return statusCodeHandbook[responseErrorCode] || statusCodeHandbook.default
  }

  private _isAbortError(error: any) {
    return error?.cause?.name === 'AbortError'
  }

  public interpret(error: any) {
    return this._isFetchError(error)
      ? this._getErrorFormHandbook(error)
      : new UnknownError(error, 500)
  }
}
