import { isSameDay } from 'date-fns'
import { computed, ref } from 'vue'
import { type LocationQueryRaw, useRoute } from 'vue-router'
import { formatDateForFilter } from '../lib'
import type { BaseSearchFilters } from '../model'

export const usePageFilters = <T extends BaseSearchFilters>(pageSize = 5) => {
  const route = useRoute()

  const getFilters: any = () => {
    const base = {
      pagination: {
        pageSize
      },
      filter: {}
    }
    if (!route.query) return base

    const { fromDateTime, tillDateTime } = route.query
    if (!fromDateTime) return base

    return {
      ...base,
      filter: {
        fromDateTime: fromDateTime as string,
        tillDateTime: tillDateTime as string
      }
    }
  }

  const updateDateFilters = (value?: Date | Date[]) => {
    if (!value) {
      delete filters.value.filter.tillDateTime
      delete filters.value.filter.fromDateTime

      return
    }

    filters.value.filter!.fromDateTime = Array.isArray(value)
      ? formatDateForFilter(value[0])
      : formatDateForFilter(value)
    filters.value.filter!.tillDateTime = Array.isArray(value)
      ? formatDateForFilter(value[1])
      : formatDateForFilter(value)
  }

  const datepickerValue = computed(() => {
    if (!filters.value?.filter?.tillDateTime || !filters.value?.filter?.fromDateTime)
      return undefined
    const { tillDateTime, fromDateTime } = filters.value.filter

    if (isSameDay(tillDateTime!, fromDateTime)) return new Date(fromDateTime)

    return [new Date(fromDateTime), new Date(tillDateTime)]
  })

  const filters = ref<T>(getFilters())

  const getQueryFromFilters = (): LocationQueryRaw => {
    return {
      ...filters.value.filter
    }
  }

  return {
    filters,
    datepickerValue,
    updateDateFilters,
    getQueryFromFilters
  }
}
