<template>
  <section class="relative flex flex-col lg:h-[496px]">
    <UiContainer tag="header" class="sr-only">
      <UiTitle>
        <UiTitle severity="h2">
          {{ value && value.venues.length > 1 ? 'Места' : 'Место' }} проведения
        </UiTitle>
      </UiTitle>
    </UiContainer>
    <template v-if="!loading && value">
      <UiContainer
        :class="[
          isLgAndDown && 'order-2',
          'static -mt-5 h-full overflow-hidden rounded-t-3xl border-y border-card-primary bg-primary pb-3 pt-5',
          'lg:mt-0 lg:rounded-none lg:border-0 lg:bg-transparent'
        ]"
      >
        <UiGradientBackground class="absolute inset-0 lg:hidden" />
        <SelectVenueInList
          v-model:active-venue="activeVenue"
          :value="uniqueVenues"
          class="relative z-10"
        />
      </UiContainer>
      <UiContainer class="top-0 z-0 order-1 !max-w-full !p-0 lg:absolute lg:order-2">
        <VenueYandexMap
          v-model:active-venue="activeVenue"
          :value="uniqueVenues"
          class="z-0 overflow-hidden rounded-none lg:rounded-2xl"
        />
      </UiContainer>
    </template>
    <template v-else>
      <EventVenuesSkeleton />
    </template>
  </section>
</template>

<script setup lang="ts">
import type { Nullable } from 'ts-helpers'
import { computed, shallowRef } from 'vue'
import { SelectVenueInList } from '@/4_features/Venue'
import type { Event } from '@/5_entities/Event'
import { type Venue, VenueYandexMap } from '@/5_entities/Venue'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiGradientBackground, UiTitle } from '@/6_shared/ui'
import EventVenuesSkeleton from './EventVenuesSkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})
const {
  lg: [_isLgAndUp, isLgAndDown]
} = useBreakpoint()
const uniqueVenues = computed(
  (): Venue[] =>
    (props.value &&
      props.value.venues.reduce((acc, item) => {
        if (!acc.find((venue) => venue.id === item.id)) acc.push(item)

        return acc
      }, [] as Venue[])) ||
    []
)

const activeVenue = shallowRef<Nullable<Venue>>(null)
</script>
