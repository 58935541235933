<template>
  <div class="flex animate-pulse">
    <div class="mr-2 aspect-default w-1/3 rounded-xl bg-secondary" />
    <div class="flex w-2/3 flex-col gap-4">
      <div class="h-4 w-full rounded-md bg-secondary" />
      <div class="h-4 w-full rounded-md bg-secondary" />
      <div class="h-4 w-full rounded-md bg-secondary" />
    </div>
  </div>
</template>
