<template>
  <div class="h-full">
    <div class="relative flex h-1/2 w-full justify-center bg-secondary">
      <div class="shape" />
      <p class="relative z-20 mb-20 mt-auto text-center text-text-secondary">
        {{ phrases[phraseIndex] }}
      </p>
    </div>
    <div class="relative h-1/2 overflow-hidden bg-secondary pt-14">
      <ul style="min-width: calc(100% + 40px)" class="-ml-5 flex flex-wrap gap-5">
        <li
          v-for="item in ITEMS_LENGTH"
          :key="item"
          class="text-text-quaternary"
          :class="animatedItems.includes(item) && 'animate'"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_384_28360)">
              <path
                d="M25.7391 32H6.26087C2.8091 32 0 29.1909 0 25.7391V9.04348C0 7.36413 1.19735 5.9589 2.78261 5.63553V3.47826C2.78261 1.56046 4.34307 -3.33786e-06 6.26087 -3.33786e-06H25.7391C27.6569 -3.33786e-06 29.2174 1.56046 29.2174 3.47826V5.63553C30.8027 5.9589 32 7.36345 32 9.04348V25.7391C32 29.1909 29.1909 32 25.7391 32ZM25.7391 1.3913H6.26087C5.11039 1.3913 4.17391 2.32778 4.17391 3.47826V5.63553C5.75917 5.9589 6.95652 7.36345 6.95652 9.04348V17.4093C7.5394 16.9677 8.25747 16.6957 9.04348 16.6957H22.9565C23.7425 16.6957 24.4606 16.9677 25.0435 17.4093V9.04348C25.0435 7.36413 26.2408 5.9589 27.8261 5.63553V3.47826C27.8261 2.32778 26.8896 1.3913 25.7391 1.3913ZM25.0435 20.1739C25.0435 19.0234 24.107 18.087 22.9565 18.087H9.04348C7.893 18.087 6.95652 19.0234 6.95652 20.1739V22.2609H25.0435V20.1739ZM30.6087 9.04348C30.6087 7.893 29.6722 6.95652 28.5217 6.95652C27.3713 6.95652 26.4348 7.893 26.4348 9.04348V22.9565C26.4348 23.3404 26.123 23.6522 25.7391 23.6522H6.26087C5.87704 23.6522 5.56522 23.3404 5.56522 22.9565V9.04348C5.56522 7.893 4.62874 6.95652 3.47826 6.95652C2.32779 6.95652 1.3913 7.893 1.3913 9.04348V25.7391C1.3913 28.4243 3.57575 30.6087 6.26087 30.6087H25.7391C28.4243 30.6087 30.6087 28.4243 30.6087 25.7391V9.04348Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_384_28360">
                <rect width="32" height="32" fill="white" transform="matrix(1 0 0 -1 0 32)" />
              </clipPath>
            </defs>
          </svg>
        </li>
      </ul>
      <div
        class="absolute bottom-0 left-0 h-4/5 w-full"
        style="
          background: linear-gradient(
            180deg,
            rgba(241, 240, 242, 0) 0%,
            rgba(241, 240, 242, 0.8) 62%,
            rgba(241, 240, 242, 1) 100%
          );
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Nullable } from 'ts-helpers'
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { getMilliseconds } from '@/6_shared/lib'

const phrases = ['Ищем лучшие места..', 'Еще несколько секунд..', 'Загружаем схему зала..']
const phraseIndex = ref(Math.floor(Math.random() * phrases.length))
const ITEMS_LENGTH = 100
const getRandomItem = () => Math.floor((Math.random() * ITEMS_LENGTH) / 2.5)
const animatedItems = reactive<number[]>([5, getRandomItem()])
let interval: Nullable<NodeJS.Timeout> = null

const animateRandomItem = () => {
  if (animatedItems.length > 2) {
    animatedItems.shift()
  }

  animatedItems.push(getRandomItem())
}

const changeText = () => (phraseIndex.value = Math.floor(Math.random() * phrases.length))

onMounted(() => {
  interval = setInterval(() => {
    changeText()
    animateRandomItem()
  }, getMilliseconds.inSeconds(2.5))
})
onBeforeUnmount(() => interval && clearInterval(interval))
</script>

<style scoped>
.animate {
  animation: chairs 2s infinite ease-in;
  transition: color;
}

.shape {
  @apply absolute -bottom-5 z-10 h-60 w-[110%] rounded-[110%/70px] border-b border-primary;
}

.shape {
  background: linear-gradient(180deg, rgba(240, 242, 248, 0) 0%, rgba(114, 121, 133, 0.1) 100%);
}

@keyframes chairs {
  from {
    color: var(--colors-text-quaternary);
  }

  50% {
    color: var(--colors-primary);
  }

  to {
    color: var(--colors-text-quaternary);
  }
}
</style>
