<template>
  <main class="py-5">
    <CategoryMainView />
    <template v-if="compilations && compilationInitialized">
      <CompilationWidget
        v-for="(compilation, index) in compilations"
        :key="compilation.slug"
        type="list"
        :value="compilation"
        :size="index > 0 && !(index % 2) ? 'small' : 'big'"
        :loading="!compilationInitialized"
        class="mb-5 lg:mb-9"
      />
    </template>
    <template v-else>
      <CompilationWidgetSkeleton v-for="skeleton in 3" :key="skeleton" class="mb-5 lg:mb-9" />
    </template>
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, onMounted } from 'vue'
import { CategoryMainView } from '@/2_pages/Category'
import { CompilationWidgetSkeleton } from '@/3_widgets/Compilation'
import { useCompilation } from '@/5_entities/Compilation'

const CompilationWidget = defineAsyncComponent({
  loader: async () => {
    const { CompilationWidget } = await import('@/3_widgets/Compilation')

    return CompilationWidget
  }
})

const { getCompilationsPack } = useCompilation()
const { compilations, compilationInitialized } = storeToRefs(useCompilation())

onMounted(() => getCompilationsPack('homepage'))
</script>
