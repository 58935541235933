<template>
  <UiContainer tag="section">
    <UiMasonry v-if="isMdAndUp" :items="value.items" :columns="isLgAndDown ? 2 : 3" gap="24px">
      <template #default="{ item }">
        <RouterLink :to="getCompilationItemLink(item)" custom v-slot="{ href, navigate }">
          <div>
            <a :href="href" @click.prevent="navigate()">
              <Component
                :is="compilationComponentsMap[item.type]"
                :value="item.object as Event"
                size="big"
                bordered
              />
            </a>
          </div>
        </RouterLink>
      </template>
    </UiMasonry>
    <ul v-else class="grid grid-cols-2 gap-3 lg:grid-cols-3">
      <RouterLink
        v-for="(item, index) in value.items"
        :key="item.object.id"
        :to="getCompilationItemLink(item)"
        custom
        v-slot="{ href, navigate }"
      >
        <li :class="index > 0 && !((index + 1) % 7) ? 'lg:col-span-1 col-span-2' : 'col-span-1'">
          <a :href="href" @click.prevent="navigate()">
            <Component
              :is="compilationComponentsMap[item.type]"
              :value="item.object as Event"
              size="big"
            />
          </a>
        </li>
      </RouterLink>
    </ul>
  </UiContainer>
</template>

<script lang="ts" setup>
import { type Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiMasonry } from '@/6_shared/ui'
import { getCompilationItemLink, compilationComponentsMap } from '../../lib'
import type { Compilation } from '../../model'

type PropType = {
  value: Compilation
}

defineProps<PropType>()
const {
  lg: [_, isLgAndDown],
  md: [isMdAndUp]
} = useBreakpoint()
</script>
