<template>
  <section class="relative">
    <template v-if="!loading && value">
      <div
        class="relative flex aspect-default w-full items-center justify-center overflow-hidden bg-tertiary lg:rounded-xl lg:shadow-poster_primary"
      >
        <UiPicture
          v-for="image in 2"
          :key="'image' + image"
          :src="value.image"
          :alt="value.title"
          :options="{ width: 725 * 1.5, enlarge: 1 }"
          :size-options="createImageResizeOptions"
          :img-classes="image > 1 ? 'object-cover' : ''"
          :class="
            image > 1
              ? 'absolute z-0 size-full object-cover opacity-20 blur-lg inset-center'
              : 'relative z-10 h-full max-h-full max-w-full object-contain'
          "
        >
          <template v-if="image < 2" #default-image>
            <UiBackupImage :letter="value.title[0]" :alt="value.title" />
          </template>
        </UiPicture>
      </div>
    </template>
    <template v-else>
      <EventPosterSkeleton />
    </template>
  </section>
</template>

<script lang="ts" setup>
import type { PictureSizesOptions } from 'img-proxy'
import type { Event } from '@/5_entities/Event'
import { Breakpoints } from '@/6_shared/config'
import { UiBackupImage, UiPicture } from '@/6_shared/ui'
import EventPosterSkeleton from './EventPosterSkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}

withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})

const createImageResizeOptions = Object.entries(Breakpoints).reduce((acc, [screen, size]) => {
  acc[screen as keyof typeof Breakpoints] = { width: size }

  return acc
}, {} as PictureSizesOptions)
</script>
