<template>
  <UiRoundedHeader sticky class="flex items-center">
    <GoBack class="mr-5 border border-primary" />
    <UiTitle>
      <template v-if="Array.isArray(selectedDates)">
        <UiDate :value="selectedDates[0]" :template="startDateTemplate" class="text-nowrap" /> -
        <UiDate :value="selectedDates[1]" :template="endDateTemplate" class="text-nowrap" />
      </template>
      <template v-else>
        <UiDate :value="selectedDates" :template="startDateTemplate" />
      </template>
    </UiTitle>
  </UiRoundedHeader>
</template>

<script setup lang="ts">
import { isSameMonth, isSameYear } from 'date-fns'
import { computed } from 'vue'
import { GoBack } from '@/4_features/Navigation'
import { UiDate, UiRoundedHeader, UiTitle } from '@/6_shared/ui'

type PropType = {
  selectedDates: Date | Date[]
}

const props = defineProps<PropType>()
const today = new Date()

const checkIsAnotherYear = (date1: Date, date2: Date = today) => !isSameYear(date1, date2)
const checkIsAnotherMonth = (date1: Date, date2: Date = today) => !isSameMonth(date1, date2)
const startDateTemplate = computed(() => {
  if (!props.selectedDates) return

  const isSingleAnotherYear =
    !Array.isArray(props.selectedDates) && checkIsAnotherYear(props.selectedDates)
  const isAnotherYearInSecondDate =
    Array.isArray(props.selectedDates) &&
    checkIsAnotherYear(props.selectedDates[0], props.selectedDates[1])
  const isMAnotherMonthInAnotherYear =
    Array.isArray(props.selectedDates) &&
    checkIsAnotherYear(props.selectedDates[0]) &&
    checkIsAnotherYear(props.selectedDates[1]) &&
    checkIsAnotherMonth(props.selectedDates[0], props.selectedDates[1])
  const isAnotherMonth = Array.isArray(props.selectedDates)
    ? checkIsAnotherMonth(props.selectedDates[0], props.selectedDates[1])
    : false
  const isSingleDate = !Array.isArray(props.selectedDates)

  return `d${isAnotherMonth || isSingleDate ? ' MMMM' : ''}${isSingleAnotherYear || isAnotherYearInSecondDate || isMAnotherMonthInAnotherYear ? ' yyyy' : ''}`
})

const endDateTemplate = computed(() => {
  if (!props.selectedDates || !Array.isArray(props.selectedDates)) return
  const isAnotherYear = checkIsAnotherYear(props.selectedDates[1])

  return `d MMMM${isAnotherYear ? ' yyyy' : ''}`
})
</script>
