<template>
  <UiButton
    severity="tertiary"
    icon
    title="Скачать тему"
    class="border border-quaternary"
    @click="downloadTheme"
  >
    <UiIcon name="arrow-down-tray" class="size-5" />
  </UiButton>
</template>

<script lang="ts" setup>
import type { Theme } from '@/5_entities/Configuration'
import { UiButton, UiIcon } from '@/6_shared/ui'

type PropType = {
  value: Theme
}

const props = defineProps<PropType>()

const downloadTheme = () => {
  const blob = new Blob([JSON.stringify(props.value)], { type: 'application/json' })
  const a = document.createElement('a')
  a.download = 'theme'
  a.href = URL.createObjectURL(blob)

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  })

  a.dispatchEvent(clickEvent)
  a.remove()
}
</script>
