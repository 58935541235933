import { EventAdapter, type EventDTO, type Event } from '@/5_entities/Event'
import type { SearchResult, SearchResultItem } from '@/6_shared/model'
import { type Compilation, type CompilationDTO } from './type.d'

export class CompilationAdapter implements Compilation {
  public title: Compilation['title']
  public slug: Compilation['slug']
  public rank: Compilation['rank']
  public items: Compilation['items']
  public pagination: Compilation['pagination']

  constructor(data: CompilationDTO) {
    this.title = data.title
    this.slug = data.slug
    this.rank = data.rank
    this.pagination = data.pagination
    this.items = this._getItems(data)
  }

  private _transformItems = (
    items: CompilationDTO['items']
  ): SearchResultItem<'event', Event>[] => {
    return items.map((item) => {
      switch (item.type) {
        case 'event':
          return {
            type: 'event',
            object: new EventAdapter(item.object as EventDTO)
          } as SearchResultItem<'event', Event>
      }
    })
  }

  private _getItems(data: CompilationDTO) {
    return this._transformItems(data.items)
  }

  public addItemsNextPage(newItems: SearchResult<'event', EventDTO>) {
    const { pagination, items } = newItems
    this.pagination = pagination
    const addedItems = this._transformItems(items)
    addedItems.forEach((item) => this.items.push(item))
  }
}
