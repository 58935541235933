import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import type { CategoryDTO, CategoryFilters } from '../model'
import { categoryErrorsHandbook } from './error'

class CategoryService extends BaseService {
  private readonly _endpoint: string = 'categories'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public get(id: number, query?: CategoryFilters) {
    return this._http.get<CategoryDTO>([this._endpoint, id].join('/'), {
      generalUrl: 'category:id',
      needCancelOnRouteChange: true,
      query: query,
      queryAsString: true,
      appMessageErrors: [404]
    })
  }

  public loadEvents(categoryId: number, query?: CategoryFilters) {
    return this._http.get<CategoryDTO>([this._endpoint, categoryId].join('/'), {
      generalUrl: 'category:id.events',
      needCancelOnRouteChange: true,
      query,
      queryAsString: true
    })
  }

  public getAll(filters?: CategoryFilters) {
    return this._http.get<CategoryDTO[]>(this._endpoint, {
      needCancelOnRouteChange: true,
      query: filters,
      queryAsString: true
    })
  }
}

const categoryService = new CategoryService(HttpService, categoryErrorsHandbook)

export { categoryService }
