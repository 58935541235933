<template>
  <header class="fixed inset-y-1 right-0 z-50 h-fit">
    <GoBack
      class="mr-3 !bg-button-secondary shadow-secondary"
      :static="staticCloseButton"
      @click="emit('close')"
    >
      <ThemeIcon name="x-mark" class="size-5 text-primary" />
    </GoBack>
  </header>
  <OpenSeanceWidget :seance-id="value" class="relative z-0" @delete-seance="emit('deleteSeance')" />
</template>

<script setup lang="ts">
import { GoBack } from '@/4_features/Navigation'
import { OpenSeanceWidget } from '@/4_features/Seance'
import { ThemeIcon } from '@/5_entities/Configuration'
import type { Seance } from '@/5_entities/Seance'

type PropType = {
  value: Seance['id']
  staticCloseButton?: boolean
}
type EmitType = {
  (e: 'deleteSeance'): void
  (e: 'close'): void
}

withDefaults(defineProps<PropType>(), {
  staticCloseButton: false
})
const emit = defineEmits<EmitType>()
</script>
