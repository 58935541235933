<template>
  <UiContainer tag="form" @submit.prevent="onFormSubmit">
    <UiInput
      ref="inputComponent"
      v-model="searchString"
      type="search"
      placeholder="Найти события и места"
      class="pl-10"
      maxlength="50"
      @update:model-value="onSearchInput"
    >
      <template #prepend>
        <ThemeIcon
          name="magnifying-glass"
          class="absolute left-4 size-4 h-full text-text-fivefold"
        />
      </template>
    </UiInput>
  </UiContainer>
</template>

<script lang="ts" setup>
import type { Nullable } from 'ts-helpers'
import { shallowRef } from 'vue'
import { ThemeIcon } from '@/5_entities/Configuration'
import { UiContainer, UiInput } from '@/6_shared/ui'
import { useSearchForEvents } from '../model'

const { searchString, onSearchInput } = useSearchForEvents()

const inputComponent = shallowRef<Nullable<typeof UiInput>>()
const onFormSubmit = () => {
  inputComponent.value && inputComponent.value.inputElement.blur()
}
</script>
