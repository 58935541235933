<template>
  <ul>
    <SeanceListItemNew
      v-for="seance in viewedItems"
      :key="seance.id"
      :value="seance"
      :size="size"
      :type="type"
      @click="emit('click', seance.id)"
    />
    <li v-if="eventId && value.length > viewedItems.length && edgeSeances" class="bg-primary">
      <SeanceTimetableButton
        :event-id="eventId"
        type="edge"
        :edge-seances="edgeSeances"
        class="block w-full !p-3 text-left text-sm font-semibold"
        @open="emit('openTimetable', $event)"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { Event } from '@/5_entities/Event'
import type { Seance } from '../../model'
import SeanceTimetableButton from '../SeanceTimetableButton/SeanceTimetableButton.vue'
import SeanceListItemNew from './SeanceListItem.vue'

type PropType = {
  value: Seance[]
  size?: 'small' | 'big'
  splitter?: boolean
  maxItems?: number
  eventId?: Event['id']
  type?: 'link' | 'button'
}

const props = withDefaults(defineProps<PropType>(), {
  size: 'big',
  splitter: false,
  maxItems: undefined,
  eventId: undefined,
  type: 'link'
})
const emit = defineEmits<EmitType>()
type EmitType = {
  (e: 'openTimetable', eventId: Event['id']): void
  (e: 'click', seanceId: Seance['id']): void
}
const viewedItems = computed(() => {
  if (!props.maxItems) return props.value

  return props.value.filter((_item, index) => index < props.maxItems!)
})

const edgeSeances = computed(() => {
  if (!props.maxItems) return

  const availableSeances = props.value.filter((_item, index) => index >= props.maxItems!)

  return availableSeances.length > 1
    ? [availableSeances[0], availableSeances[availableSeances.length - 1]]
    : [availableSeances[0]]
})
</script>
