<template>
  <UiRoundedHeader v-bind="{ ...$attrs }" sticky>
    <div class="mb-6 flex items-center">
      <GoBack class="mr-5 border border-primary" />
      <UiTitle v-if="value && !loading">
        {{ value }}
      </UiTitle>
      <template v-else>
        <div class="h-9 w-2/3 animate-pulse bg-secondary" />
      </template>
    </div>
    <nav class="flex gap-3 overflow-y-scroll">
      <UiButton
        v-show="date"
        severity="tertiary"
        size="small"
        class="rounded"
        @click="emit('update:date', undefined)"
      >
        <UiIcon name="x-mark" class="size-4" />
      </UiButton>
      <UiButton size="small" severity="tertiary" class="rounded" @click="showCalendar = true">
        <UiIcon name="calendar" class="size-4 text-icon-secondary" />
        <div v-show="date" class="absolute right-0.5 top-0 size-2 rounded bg-main" />
      </UiButton>
      <UiDateHelpers
        :model-value="date"
        @update:model-value="emit('update:date', $event ?? undefined)"
      />
    </nav>
  </UiRoundedHeader>

  <UiDialog
    v-model="showCalendar"
    position="bottom"
    close-position="left"
    close-classes="-top-1 left-2"
    class="h-[97vh] rounded-t-3xl bg-primary pt-4"
    swipe-close
  >
    <template #header>
      <div class="w-full bg-primary text-center">
        <UiTitle severity="h2" class="mx-auto">Выбрать дату</UiTitle>
      </div>
    </template>
    <UiDatePicker
      range
      need-confirm
      :model-value="date"
      @update:model-value="emit('update:date', $event)"
      @close="showCalendar = false"
    />
  </UiDialog>
</template>

<script setup lang="ts">
import type { Undefinable } from 'ts-helpers'
import { ref } from 'vue'
import { GoBack } from '@/4_features/Navigation'
import {
  UiButton,
  UiDateHelpers,
  UiDatePicker,
  UiDialog,
  UiIcon,
  UiRoundedHeader,
  UiTitle
} from '@/6_shared/ui'

type PropType = {
  date: Undefinable<Date | Date[]>
  value?: string
  loading?: boolean
}
type EmitType = {
  (e: 'update:date', date: Undefinable<Date | Date[]>): void
}
withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false
})
const emit = defineEmits<EmitType>()

const showCalendar = ref(false)

defineOptions({
  inheritAttrs: false
})
</script>
