<template>
  <EventDateSimple
    v-if="!isWrapEvent"
    :seances="seances"
    :first-seance="firstSeance"
    :event-months="eventMonths"
    :is-only-one-seance="isOnlyOneSeance"
    :capitalize-first="capitalizeFirst"
  />
  <EventDateGap v-else-if="firstSeance?.endsAt" :seance="firstSeance" class="font-medium" />
</template>

<script setup lang="ts">
import { getMonth, isAfter, isSameDay } from 'date-fns'
import { computed } from 'vue'
import type { Event } from '../../model'
import EventDateGap from './EventDateGap.vue'
import EventDateSimple from './EventDateSimple.vue'

type PropType = {
  value: Event
  capitalizeFirst?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  capitalizeFirst: false
})

const seances = computed(() => {
  const seances = [...props.value.seances]
  seances.sort((a, b) => {
    if (isAfter(a.beginsAt, b.beginsAt)) return 1

    return -1
  })

  return seances
})
const firstSeance = computed(() => seances.value[0])

// простое мероприятие без переноса дня окончания в любом виде
const isWrapEvent = computed(
  () =>
    isOnlyOneSeance.value &&
    seances.value.some((seance) => seance?.endsAt && !isSameDay(seance.beginsAt, seance.endsAt))
)

// в мероприятии один сеанс
const isOnlyOneSeance = computed(() => seances.value.length === 1)

//месяцы мероприятия
const eventMonths = computed(() => {
  const months: number[] = []

  seances.value.forEach((seance) => {
    const seanceMonth = getMonth(seance.beginsAt)

    if (!months.includes(seanceMonth)) months.push(seanceMonth)
  })

  return months
})
</script>
