<template>
  <main>
    <SeanceWidget :value="Number(params.id)" @delete-seance="onSeanceDelete" />
  </main>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { SeanceWidget } from '@/3_widgets/Seance'
import { useEvent } from '@/5_entities/Event'
import { useGoBack } from '@/6_shared/composables'

const router = useRouter()
const { params } = useRoute()
const { deleteEventSeance } = useEvent()
const { event } = storeToRefs(useEvent())
const { goBack } = useGoBack()

const onSeanceDelete = () => {
  if (event.value) {
    deleteEventSeance(Number(params.id))

    return goBack()
  }

  router.push({ name: 'HomeView' })
}
</script>
