<template>
  <div class="relative flex size-full flex-col bg-overlay py-8">
    <TransitionFade>
      <UiButton
        v-show="showPrev"
        severity="secondary"
        icon
        class="prev-gallery-full group !absolute left-6 z-10 hidden size-12 !bg-transparent inset-y-center lg:flex"
      >
        <div
          class="absolute inset-0 size-full rounded-full bg-light opacity-50 transition-all group-hover:opacity-100 group-focus:opacity-100"
        />
        <UiIcon
          name="arrow-right"
          class="relative size-6 rotate-180 text-icon-secondary transition-transform group-hover:-translate-x-px"
        />
      </UiButton>
    </TransitionFade>

    <div
      class="absolute -inset-1/2 flex size-[200%] items-center justify-center blur-[65px] lg:inset-[-30%] lg:size-[160%]"
    >
      <UiPicture
        :src="value[0].object.url"
        img-classes="object-cover"
        class="h-full overflow-hidden object-cover"
      />
      <div class="absolute inset-0 size-full bg-overlay" />
    </div>
    <UiTitle severity="h3" class="relative mb-2 text-center !font-bold text-light">
      {{ eventTitle }}
    </UiTitle>
    <UiSwiper>
      <Swiper
        ref="swiper"
        :navigation="{
          prevEl: '.prev-gallery-full',
          nextEl: '.next-gallery-full'
        }"
        :modules="[Navigation]"
        :initial-slide="initialSlide"
        centered-slides
        watch-overflow
        class="my-auto max-h-full w-full"
        @slide-change="slideChange"
        @transition-start="onSwiperEdge"
      >
        <SwiperSlide
          v-for="media in value"
          :key="media.object.id"
          class="flex h-full max-h-screen justify-center px-4"
        >
          <div class="flex h-full flex-col items-center justify-center">
            <UiPicture
              :src="media.object.url"
              class="max-h-screen overflow-hidden rounded-xl object-contain"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </UiSwiper>
    <TransitionFade>
      <UiButton
        v-show="showNext"
        severity="secondary"
        icon
        class="next-gallery-full group !absolute right-6 z-10 hidden size-12 !bg-transparent inset-y-center lg:flex"
      >
        <div
          class="absolute inset-0 size-full rounded-full bg-light opacity-50 transition-all group-hover:opacity-100 group-focus:opacity-100"
        />
        <UiIcon
          name="arrow-right"
          class="relative size-6 text-icon-secondary transition-transform group-hover:translate-x-px"
        />
      </UiButton>
    </TransitionFade>
    <div class="absolute bottom-20 left-0 z-10 mb-2 w-full text-center !font-bold text-light">
      {{ activeSlide }} / {{ value.length }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import type { Swiper as SwiperType } from 'swiper'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { onMounted, onUnmounted, ref } from 'vue'
import type { Event } from '@/5_entities/Event'
import { setUserScale } from '@/6_shared/lib'
import { UiButton, UiIcon, UiPicture, UiSwiper, UiTitle } from '@/6_shared/ui'

type PropType = {
  eventTitle: string
  value: Event['gallery']
  initialSlide?: number
}

const props = withDefaults(defineProps<PropType>(), {
  initialSlide: 0
})
const showNext = ref(true)
const showPrev = ref(false)
const activeSlide = ref(props.initialSlide ? props.initialSlide + 1 : 1)
const slideChange = ({ activeIndex }: SwiperType) => {
  activeSlide.value = activeIndex + 1
}
const onSwiperEdge = (swiper: SwiperType) => {
  const { isEnd, isBeginning } = swiper

  showNext.value = !isEnd
  showPrev.value = !isBeginning
}

onMounted(() => setUserScale.enable())
onUnmounted(() => setUserScale.disable())
</script>
