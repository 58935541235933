<template>
  <div>
    <ul class="flex flex-col gap-y-4 lg:gap-y-6">
      <li v-for="[date, seancesByVenue] in seancesByDaysAndVenues" :key="date">
        <header class="px-2">
          <UiDate :value="date" template="d" class="inline font-semibold" />&nbsp;<UiDate
            :value="date"
            :template="isSameYear(date, new Date()) ? 'MMMM' : 'MMMM yyyy'"
            class="inline text-xs font-semibold lg:text-base"
          />&nbsp;<UiDate
            :value="date"
            template="EEEE"
            :class="isWeekend(date) ? 'text-text-primary' : 'text-text-secondary'"
            class="inline text-xs"
          />
        </header>
        <ul v-if="isLgAndUp" class="flex flex-col gap-y-4">
          <li v-for="(seances, venue, index) in seancesByVenue" :key="venue + index">
            <header class="mb-1 hidden items-start gap-0.5 px-2 lg:flex">
              <UiIcon name="map-pin" class="mt-px size-3 min-w-3" />
              <p class="text-xs text-text-secondary">
                {{ venue }}
              </p>
            </header>
            <ul class="mt-2 flex flex-wrap gap-2">
              <li v-for="seance in seances" :key="seance.id">
                <button
                  type="button"
                  class="flex flex-col rounded-xl border border-card-primary bg-primary p-2 text-left shadow-card_primary transition-all hover:border-card-primary-hover hover:shadow-card_primary_hover focus:border-card-primary-focus focus:shadow-card_primary_hover"
                  :disabled="!seance.ticketsLeft"
                  @click="onSeanceOpen(seance.id)"
                >
                  <UiDate :value="seance.beginsAt" template="HH:mm" class="font-semibold" />
                  <span v-if="seance.ticketsLeft" class="text-xs font-semibold text-text-primary">
                    <span class="font-medium">от</span> {{ toRub(seance.priceRange.min) }}
                  </span>
                  <span v-else class="text-center font-semibold text-text-secondary">
                    распродано
                  </span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <SeanceListNew
          v-else
          :value="Object.values(seancesByVenue).flat()"
          splitter
          class="overflow-hidden rounded-xl border border-card-primary shadow-poster_primary"
          type="button"
          @click="onSeanceOpen"
        />
      </li>
    </ul>

    <UiDialog :model-value="!!showingSeance" save-scroll-lock @update:model-value="onSeanceClose">
      <SeanceWidget
        v-if="showingSeance"
        :value="showingSeance"
        static-close-button
        @delete-seance="onSeanceDelete"
        @close="onSeanceClose"
      />
    </UiDialog>
  </div>
</template>

<script lang="ts" setup>
import { isWeekend, isSameYear } from 'date-fns'
import type { Undefinable } from 'ts-helpers'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { SeanceWidget } from '@/3_widgets/Seance'
import { useEvent } from '@/5_entities/Event'
import { type Seance } from '@/5_entities/Seance'
import { SeanceListNew } from '@/5_entities/Seance'
import { useQueryParamsOpenState } from '@/6_shared/composables'
import { toRub, useBreakpoint } from '@/6_shared/lib'
import { UiDate, UiDialog, UiIcon } from '@/6_shared/ui'

type PropType = {
  value: Seance[][]
}
const props = defineProps<PropType>()
const {
  lg: [isLgAndUp]
} = useBreakpoint()
const route = useRoute()
const showingSeance = ref<Undefinable<Seance['id']>>(undefined)
const { setParamInRouteQuery } = useQueryParamsOpenState('seance', () => {
  if (showingSeance.value) {
    showingSeance.value = undefined
  }

  setSeanceFromRouteQuery()
})
const setSeanceFromRouteQuery = () => {
  route.query?.seance && (showingSeance.value = Number(route.query.seance))
}
onMounted(() => setSeanceFromRouteQuery())
const onSeanceOpen = (id: Seance['id']) => {
  showingSeance.value = id

  setParamInRouteQuery(id.toString())
}
const { deleteEventSeance } = useEvent()
const onSeanceDelete = () => {
  showingSeance.value && deleteEventSeance(showingSeance.value)
  showingSeance.value = undefined
}

const onSeanceClose = () => {
  showingSeance.value = undefined
  setParamInRouteQuery(undefined)
}

const seancesByDaysAndVenues = computed(() => {
  return props.value.reduce(
    (acc, item) => {
      const key = item[0].beginsAt
      const value = item.reduce(
        (accSeances, seance) => {
          const venue = seance.venue?.title

          if (!venue) return accSeances

          if (!(venue in accSeances)) {
            accSeances[venue] = [seance]
          } else {
            accSeances[venue].push(seance)
          }

          return accSeances
        },
        {} as Record<string, Seance[]>
      )

      acc.set(key, value)

      return acc
    },
    new Map() as Map<string, Record<string, Seance[]>>
  )
})
</script>
