<template>
  <div>
    <VenueRepertoireList :value="visibleEvents" />
    <UiButton
      v-if="valueCount > MAX_VISIBLE_EVENTS"
      tag="RouterLink"
      :to="{ name: 'VenueRepertoireView', params: { id: venueId } }"
      severity="secondary"
      class="block w-full text-center"
    >
      Все {{ toPlural(valueCount, 'мероприятие', 'мероприятия', 'мероприятий') }}
    </UiButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { type Venue, VenueRepertoireList } from '@/5_entities/Venue'
import { toPlural } from '@/6_shared/lib'
import { UiButton } from '@/6_shared/ui'

type PropType = {
  value: Venue['repertoire']
  venueId: Venue['id']
  valueCount: Venue['repertoireEventsCount']
}

const props = defineProps<PropType>()

const MAX_VISIBLE_EVENTS = 10
const visibleEvents = computed(() => {
  if (!props.value) return []

  return props.value.filter((_, index) => index < MAX_VISIBLE_EVENTS)
})
</script>
