<template>
  <div class="flex animate-pulse flex-col">
    <div class="mb-3 aspect-default w-full rounded-xl bg-secondary" />
    <div class="flex w-full flex-col gap-4">
      <div class="h-4 w-full rounded-md bg-secondary" />
      <div class="h-5 w-full rounded-md bg-secondary" />
      <div class="h-4 w-full rounded-md bg-secondary" />
    </div>
  </div>
</template>
