import { EventCard } from '@/5_entities/Event'
import type { Compilation } from '../model'

export const getCompilationItemLink = (item: Compilation['items'][number]) => {
  switch (item.type) {
    case 'event':
      return { name: 'EventView', params: { id: item.object.id } }
  }
}

export const compilationComponentsMap: Record<
  Compilation['items'][number]['type'],
  typeof EventCard
> = {
  event: EventCard
}
